import React from "react";
import Footer from "../PageLayout/Footer";
import AboutPage from "./AboutPage";
import Header5 from "../PageLayout/header5";



export default function AboutHome(){
    return(
        <>
        <Header5 />
            <AboutPage />
        <Footer />
        </>
    )
}