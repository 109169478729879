import React, { useState, useContext } from "react";
import ApiContext from "../globalStore/ApiContext";
import CreateRecordModal from "../Modal/CreateRecordModal";
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import { ExclamationTriangleFill } from 'react-bootstrap-icons';

export default function HomePostTop() {
    const { apiData } = useContext(ApiContext);
    const { t } = useTranslation();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [data, setData] = useState([]);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleCreate = (newRecord) => {
        // Update the data state with the new record
        setData([...data, newRecord]);
    };

    return (
        <>
            <div className="post-topbar">
                <div className="user-picy">
                    {apiData[0].photo ? (
                        <img src={`${apiData[0].photo}`} alt={`User ${apiData[0].id}`} id="avatar" 
                            style={{ cursor: 'pointer', width: '60px', borderRadius: '10px' }} />
                    ) : (
                        <img src={`https://robohash.org/${apiData[0].id}`} 
                            id="avatar" alt="" style={{ cursor: 'pointer', width: '60px' }} />
                    )}
                </div>
                {!apiData[0].is_staff && (
                    <div className="post-st">
                        {apiData[0].user_type === 'player' ? (
                            <button className="post_project" onClick={openModal}>
                                {t('postVideo')}
                            </button>
                        ) : (
                            <Alert variant="warning" style={{fontSize:'13px', padding:'5px'}}>
                                <ExclamationTriangleFill /> {t('youNeedPlayerAccount')}
                            </Alert>
                        )}
                    </div>
                )}
                {apiData[0].user_type === 'player' && (
                    <CreateRecordModal
                        isOpen={modalIsOpen}
                        onClose={closeModal}
                        onCreate={handleCreate}
                        apiUrl="/meanc/video/"
                        initialFormData={{
                            title:'',
                            //description:'',
                            category: '',
                            video_file: '',
                            uploaded_by: apiData[0]?.id
                        }}
                    />
                )}
            </div>
        </>
    );
}
