import React from "react";
import Header2 from "../PageLayout/Header2";
import Footer from "../PageLayout/Footer";
import ChatComponent from "./ChatComponent";


export default function ChatHome(){
    return (
        <>
            <Header2 />
                <ChatComponent />
            <Footer />
        </>
    )
}