import React from 'react';
import './AboutPage.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <div className="about-container">
      <section
        className="cover-secs"
        style={{
          position: 'relative',
          width: '100%',
          height: '150px',
          backgroundImage: 'url("https://www.transparenttextures.com/patterns/clean-gray-paper.png")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <header className="about-header">
          <h1>{t('abouter.header.title')}</h1>
          <p>{t('abouter.header.description')}</p>
        </header>
      </section>

      <section className="about-vision">
        <h2>{t('abouter.mission.title')}</h2>
        <p>{t('abouter.mission.description')}</p>
      </section>

      <section className="about-features">
        <h2>{t('abouter.features.title')}</h2>
        <div className="feature-list">
          <div className="feature-item">
            <h3>{t('abouter.features.global_reach.title')}</h3>
            <img src="images/global_sports_connections.jpg" alt={t('abouter.features.global_reach.title')} className="feature-image" />
            <p>{t('abouter.features.global_reach.description')}</p>
          </div>
          <div className="feature-item">
            <h3>{t('abouter.features.multilingual_support.title')}</h3>
            <img src="images/Multilingual_Support.jpg" alt={t('abouter.features.multilingual_support.title')} className="feature-image" />
            <p>{t('abouter.features.multilingual_support.description')}</p>
          </div>
          <div className="feature-item">
            <h3>{t('abouter.features.video_review.title')}</h3>
            <img src="images/Manual_Video_Review.jpg" alt={t('abouter.features.video_review.title')} className="feature-image" />
            <p>{t('abouter.features.video_review.description')}</p>
          </div>
          <div className="feature-item">
            <h3>{t('abouter.features.ai_powered.title')}</h3>
            <img src="images/AI_Powered_Recommendations.jpg" alt={t('abouter.features.ai_powered.title')} className="feature-image" />
            <p>{t('abouter.features.ai_powered.description')}</p>
          </div>
        </div>
      </section>

      <section className="about-vision">
        <h2>{t('abouter.vision.title')}</h2>
        <p>{t('abouter.vision.description')}</p>
      </section>

      <section className="about-join-us">
        <h2>{t('abouter.join_us.title')}</h2>
        <p>{t('abouter.join_us.description')}</p>
        <div className="join-us-image-placeholder">
          <img src="images/bg1.jpg" alt={t('abouter.join_us.title')} />
        </div>
      </section>

      <Link to="/" className="floating-signin-btn">
        <FontAwesomeIcon icon={faSignIn} /> {t('abouter.signin_button')}
      </Link>
    </div>
  );
};

export default AboutPage;
