import React from 'react';

const LoadingSkeleton = () => (
    <div className="loading-skeleton">
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <div style={{ backgroundColor: '#ccc', width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}></div>
            <div style={{ flex: 1, height: '20px', backgroundColor: '#eee' }}></div>
        </div>
    </div>
);

export default LoadingSkeleton;
