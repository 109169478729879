import React, { useState, useEffect } from "react";
import $ from 'jquery';
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import SignInfo from "./SignInfo";
import Footer from "../PageLayout/Footer";
import Header4 from "../PageLayout/header4";
import { useTranslation } from 'react-i18next';

export default function Sign() {
    const [token, setToken] = useState("");
    const [referrerId, setReferrerId] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        "use strict";
        //  ============= SIGNIN CONTROL FUNCTION =========

        $('.sign-control li').on("click", function() {
            var tab_id = $(this).attr('data-tab');
            $('.sign-control li').removeClass('current');
            $('.sign_in_sec').removeClass('current');
            $(this).addClass('current animated fadeIn');
            $("#" + tab_id).addClass('current animated fadeIn');
            return false;
        });

        //  ============= SIGNIN TAB FUNCTIONALITY =========

        $('.signup-tab ul li').on("click", function() {
            var tab_id = $(this).attr('data-tab');
            $('.signup-tab ul li').removeClass('current');
            $('.dff-tab').removeClass('current');
            $(this).addClass('current animated fadeIn');
            $("#" + tab_id).addClass('current animated fadeIn');
            return false;
        });

        // Check for referrer parameter in URL
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('referrer')) {
            const referrerId = urlParams.get('referrer');
            console.log("Referral ID detected:", referrerId);
            setReferrerId(referrerId); // Store referrer ID in state
        } else {
            console.log("No referral ID detected. Regular URL.");
        }
    }, []);

    useEffect(() => {
        setToken(localStorage.getItem('userId'));
        console.log("token has been updated:", token);
        if (token) {
            localStorage.setItem('userId', token);
            window.location.href = '/home';
        }
    }, [token]);

    return (
        <div className="sign-in">
            <div className="wrapper">
                <Header4 />
                <div className="sign-in-page">
                    <div className="signin-popup">
                        <div className="signin-pop">
                            <div className="row">
                                <div className="col-lg-6">
                                    <SignInfo />
                                </div>
                                <div className="col-lg-6">
                                    <div className="login-sec">
                                        <ul className="sign-control">
                                            <li data-tab="tab-1" className="current"><a href="#" title="">{t('sign_in')}</a></li>
                                            <li data-tab="tab-2"><a href="#" title="">{t('sign_up')}</a></li>
                                        </ul>
                                        <SignIn />
                                        <SignUp referrerId={referrerId} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <Footer />
            </div>
        </div>
    );
}
