import React from 'react';
import './Privacy.css';
const CopyrightPolicy = () => {
  return (
    <div className = "privacy-policy">
      <button onClick={() => window.history.back()} style={{padding: '10px 20px', borderRadius: '10px', background: '#3498db', color: '#fff', border: 'none', fontWeight: 'bold', cursor: 'pointer'}}>Go to Home</button>
      <h1>Copyright Policy</h1>
      <p>At Meanc, we respect the intellectual property rights of others and we expect our users to do the same. This Copyright Policy outlines our policies and procedures for handling copyright infringement claims.</p>
      <h2>Reporting Copyright Infringement</h2>
      <p>If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement, please provide us with the following information:</p>
      <ul>
        <li>A description of the copyrighted work that you claim has been infringed;</li>
        <li>The location of the material on our platform that you claim is infringing, with enough detail so that we may locate it on our platform;</li>
        <li>Your address, telephone number, and email address;</li>
        <li>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>
        <li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</li>
      </ul>
      <p>You can contact us at [insert contact email] to submit a copyright infringement claim.</p>
      <h2>Counter-Notification</h2>
      <p>If you believe that material you posted on our platform was removed or access to it was disabled in error, you may submit a counter-notification by contacting us at [insert contact email]. Your counter-notification must include the following:</p>
      <ul>
        <li>Your name, address, and telephone number;</li>
        <li>The location of the material on our platform that was removed or disabled;</li>
        <li>A statement by you, made under penalty of perjury, that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material;</li>
        <li>A statement by you that you consent to the jurisdiction of the Federal District Court for the judicial district in which your address is located, or if your address is outside of the United States, for any judicial district in which Meanc may be found, and that you will accept service of process from the person who provided the original DMCA notification or an agent of such person;</li>
        <li>Your electronic or physical signature.</li>
      </ul>
      <h2>Repeat Infringers</h2>
      <p>It is our policy to terminate the accounts of repeat infringers. If we receive multiple infringement notices regarding a particular user, we may, at our discretion, terminate that user's account.</p>
    </div>
  );
};

export default CopyrightPolicy;
