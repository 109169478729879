import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

function VideoFileDropZone({ setFormData, setFile }) {
  const [fileName, setFileName] = useState(""); // State to store the name of the uploaded file
  const { t } = useTranslation();

  const onDrop = React.useCallback(acceptedFiles => {
    // Assume single file and handle it
    const file = acceptedFiles[0];
    console.log(file); // You can see the selected file in the console
    setFormData((prevData) => ({
      ...prevData,
      video_file: file,
    }));
    setFile(file);
    setFileName(file.name); // Store the file name
  }, [setFormData, setFile]); // Add setFormData and setFile to the dependencies array

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'video/*' });

  return (
    <div {...getRootProps()} className="dropzone">
      <input {...getInputProps()} />
      {
        fileName ? 
          <p>{t('fileAdded')}{fileName}</p> : // Display the file name if a file has been added
          isDragActive ?
            <p>{t('dropVideoHere')}</p> :
            <p>{t('dragDropVideo')}</p>
      }
    </div>
  );
}

export default VideoFileDropZone;
