import React from 'react';
import './Privacy.css';

const CookiesPolicy = () => {
  return (
    <div className = "privacy-policy">
      <button onClick={() => window.history.back()} style={{padding: '10px 20px', borderRadius: '10px', background: '#3498db', color: '#fff', border: 'none', fontWeight: 'bold', cursor: 'pointer'}}>Go to Home</button>
      <h1>Cookies Policy</h1>
      <p>Welcome to our Cookies Policy page. This page explains how we use cookies on our website.</p>
      <h2>What are cookies?</h2>
      <p>Cookies are small text files that are placed on your device when you visit our website. They are used to make our website work more efficiently and to provide information to us about how users interact with our website.</p>
      <h2>How do we use cookies?</h2>
      <p>We use cookies for a variety of purposes, including:</p>
      <ul>
        <li>To provide you with a better user experience on our website;</li>
        <li>To analyze how users interact with our website and to improve our website;</li>
        <li>To personalize your experience on our website;</li>
        <li>To provide you with targeted advertising based on your browsing behavior.</li>
      </ul>
      <h2>What types of cookies do we use?</h2>
      <p>We use both session cookies and persistent cookies on our website. Session cookies are temporary cookies that are erased when you close your browser. Persistent cookies remain on your device for a set period of time or until you delete them.</p>
      <h2>How to control cookies?</h2>
      <p>You can control cookies through your browser settings. Most browsers allow you to refuse cookies or to delete cookies that have already been placed on your device. However, if you choose to block or delete cookies, some features of our website may not work as intended.</p>
      <h2>Changes to our Cookies Policy</h2>
      <p>We may update our Cookies Policy from time to time. We will notify you of any changes by posting the new policy on this page.</p>
      <h2>Contact Us</h2>
      <p>If you have any questions or comments about our Cookies Policy, please contact us.</p>
    </div>
  );
};

export default CookiesPolicy;
