import React from 'react';
import './Privacy.css';

const CommunityGuidelines = () => {
  return (
    <div className = "privacy-policy">
      <button onClick={() => window.history.back()} style={{padding: '10px 20px', borderRadius: '10px', background: '#3498db', color: '#fff', border: 'none', fontWeight: 'bold', cursor: 'pointer'}}>Go to Home</button>
      <h1>Community Guidelines</h1>
      <p>Welcome to our community! We're excited to have you join us and we want to make sure that everyone feels safe and respected while participating in our platform. To help ensure that, we've put together these community guidelines that we ask all users to follow:</p>
      <ul>
        <li>Be respectful of others. Treat others as you would like to be treated and avoid posting anything that is hateful, discriminatory, or offensive.</li>
        <li>Keep it legal. Do not post anything that is illegal, fraudulent, or otherwise in violation of any applicable laws or regulations.</li>
        <li>Stay on topic. Make sure that your posts and comments are relevant to the topic at hand and do not derail the conversation.</li>
        <li>Be truthful. Do not post anything that is intentionally misleading or false.</li>
        <li>Respect others' privacy. Do not share personal or confidential information about others without their permission.</li>
        <li>Report violations. If you see any violations of these guidelines or any inappropriate behavior, please report it to us immediately.</li>
        <li>Consequences for violations. We reserve the right to remove any content or suspend or terminate any user's account for any violations of these guidelines or our terms of service.</li>
      </ul>
      <p>Thank you for helping us maintain a positive and respectful community!</p>
    </div>
  );
};

export default CommunityGuidelines;
