// SearchData.js
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';


export default function SearchData({ searchTerm, setSearchTerm }) {
    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
    };
    const { t } = useTranslation();
  
    return (
      <div className="company-title">
        <h3 style={{marginLeft:'15px', marginRight:'10px'}}>{t('watchTopAthletes')}</h3>
        <div className="search-sec">
            <div className="search-box">
              <form onSubmit={(e) => e.preventDefault()}>
                <input type="text" name="search" placeholder="Search keywords" value={searchTerm} onChange={handleSearchChange} />
                <button type="submit">Search</button>
              </form>
            </div>
        </div>
      </div>
    );
  }
  