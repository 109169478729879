import React from 'react';
import './Privacy.css';

const UserDataDeletion = () => {
return (
<div className="rivacy-policy">
<button onClick={() => window.location.href = '/'} style={{padding: '10px 20px', borderRadius: '10px', background: '#3498db', color: '#fff', border: 'none', fontWeight: 'bold', cursor: 'pointer'}}>Go to Home</button>
<h1>User Data Deletion</h1>
<p>If you want to delete your personal data from our system, please contact us at privacy@https://meancapp.azurewebsites.net/. We will respond to your request within 30 days and delete your data as required by applicable laws.</p>
</div>
);
};

export default UserDataDeletion;