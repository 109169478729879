import React from 'react';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(enLocale); // Register the locale you want to use

const CountryName = ({ countryCode }) => {
  // Ensure the countryCode is in uppercase
  const normalizedCountryCode = countryCode ? countryCode.toUpperCase() : '';

  const countryName = countries.getName(normalizedCountryCode, 'en');

  return (
    <div>
      {countryName ? countryName : 'Unknown country code'}
    </div>
  );
};

export default CountryName;
