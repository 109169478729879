import React from 'react';
import { Row, Col, ListGroup, Form, Button } from 'react-bootstrap';


export default function PlayerProfile({ extraData, isEditing,user,handleEditClick, formData, handleInputChange, handleSaveClick }) {
    return (
        <div className="col-lg-6">
            <div className="main-ws-sec">
                <div className="user-tab-sec">
                    <h3 style={{marginTop:'20px'}}>{user?.first_name || "N/A"} {user?.last_name || "N/A"}</h3>
                    <div className="star-descp">
                    <span>
                        Play position - {' '}
                        {formData.play_position ? (
                            <strong style={{ color: 'black',fontWeight: 'bold' }}>{formData.play_position}</strong>
                        ) : (
                            <strong><div className="loader"></div></strong>
                        )}
                    </span>

                        <button
                            style={{
                                float: 'right',
                                background: '#2980b9',
                                border: 'none',
                                boxShadow: '0px 24px #fff',
                                padding: '10px',
                                color: '#fff',
                            }}
                            onClick={isEditing ? handleSaveClick : handleEditClick}  // Toggle between edit and save
                        >
                            {isEditing ? 'Cancel' : 'Edit'}
                        </button>
                    </div>
                    <div className="tab-feed">
                        <Row>
                            <Col xs={6}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item className="info-item">
                                        <div className="info-label">Height</div>
                                        <div className="info-data">

                                            {formData.height? formData.height:<div className="loader"></div>}
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="info-item">
                                        <div className="info-label">Weight</div>
                                        <div className="info-data">
                                            {formData.weight? formData.weight:<div className="loader"></div>}
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="info-item">
                                        <div className="info-label">Hometown</div>
                                        <div className="info-data">
                                            {formData.hometown? formData.hometown:<div className="loader"></div>}
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="info-item">
                                        <div className="info-label">Followers</div>
                                        <div className="info-data">{user?.follower_count || '0'}</div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                            <Col xs={6}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item className="info-item">
                                        <div className="info-label">Club</div>
                                        <div className="info-data">
                                            {formData.team_name}
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="info-item">
                                        <div className="info-label">GPA</div>
                                        <div className="info-data">3.7/4.00</div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="info-item">
                                        <div className="info-label">Play position</div>
                                        <div className="info-data">
                                            {formData.play_position? formData.play_position:<div className="loader"></div>}
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="info-item">
                                        <div className="info-label">School</div>
                                        <div className="info-data">
                                            {formData.school? formData.school:<div className="loader"></div>}
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
}
