import React from 'react';
import { FaTimes } from 'react-icons/fa';  // Importing the close icon

export default function DeleteButton ({ onDelete }) {
  return (
    <button 
      onClick={onDelete} 
      style={{ 
        position: 'absolute', 
        top: '10px', 
        right: '10px', 
        border: 'none', 
        background: 'none',
        cursor: 'pointer',
        zIndex: 1000 
      }}
      aria-label="Delete"
    >
      <FaTimes color="red" size="20px" />
    </button>
  );
};

