import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import ApiContext from "../globalStore/ApiContext";
//import SearchBar from "./OLDSearchBar";
import $ from 'jquery';

export default function Header(){
    
    //const [loading, seTloading] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const ref = useRef(null);
    const token = localStorage.getItem('userId');
    const { apiData } = useContext(ApiContext);
    const [isActive, setIsActive] = useState(false);

    const handleMenuClick = () => {
        setIsActive((prev) => !prev);
    };
    // handle the user-seeting div//
    const handleUserClicker = () => {
        setShowProfile(!showProfile);
        console.log("This is the old value of profile:", showProfile);
    };  
    useEffect(() => {
        console.log("This is the new value of profile:", showProfile);
      }, [showProfile]);  
      const blockStyle = {
		//display: isBlockVisible ? 'inline-block' : 'none',
        display: showProfile ? 'block' : 'none',
  	    }; 
      
    useEffect(()=>{
        if (!token){
        window.location.href = '/';
        }
    },[]);      
    const handleLogout=(e)=>{
        localStorage.removeItem("userId")
        window.location.href = '/';
      }
    return (
      <div className="wrapper">
        <header>
            <div className="container">
                <div className="header-data">
                    <div className="logo">
                            <Link className="active" to="/home"><img src="images/logo12.png" alt="logo" /></Link>
                    </div>
                    {/*<SearchBar />*/}
                    <nav className={isActive ? "active" : ""}>
                        <ul>
                            <li style={{ background:'green' }}>
                                    
                                    <span><i className="las la-comment" style={{color:'white'}}></i></span>
                                    
                                    <Link className="active" to="/feedback">Feedback</Link>
                            
                            </li>
                            <li>
                                
                                    <span><i className="las la-home" style={{color:'white'}}></i></span>
                                    
                                    <Link className="active" to="/home">Home</Link>
                               
                            </li>
                            <li>
                                
                                    <span><i className="lab la-youtube" style={{color:'white'}}></i></span>
                                    <Link to="/videos">Athletes videos</Link>
                            
                                {/* <ul>
                                    <li><a href="companies.html" title="">Recruiter</a></li>
                                    <li><a href="company-profile.html" title="">Company Profile</a></li>
                                </ul> */}
                            </li>
                           
                            <li>
                                    <span><i class="las la-chess-knight" style={{color:'white'}}></i></span>
                                    <Link to="/recruiters">Recruiters</Link>
                                
                            </li>
                            <li onClick={handleLogout}>
                                    <span> <i class="las la-sign-out-alt" style={{color:'white'}}></i></span>
                                    <Link>Logout</Link>
                            </li>
                            {/* <li>
                                <a href="profiles.html" title="">
                                    <span><img src="images/icon4.png" alt="" /></span>
                                    Profiles
                                </a>
                                <ul>
                                    <li><a href="user-profile.html" title="">User Profile</a></li>
                                    <li><a href="my-profile-feed.html" title="">my-profile-feed</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="jobs.html" title="">
                                    <span><img src="images/icon5.png" alt="" /></span>
                                    Jobs
                                </a>
                            </li>
                            */}
                            {/*<li>
                                <a href="#" title="" className="not-box-openm">
                                    <span><img src="images/icon6.png" alt="" /></span>
                                    Messages
                                </a>
                                <div className="notification-box msg" id="message">
                                    <div className="nt-title">
                                        <h4>Setting</h4>
                                        <a href="#" title="">Clear all</a>
                                    </div>
                                    <div className="nott-list">
                                        <div className="notfication-details">
                                            <div className="noty-user-img">
                                                <img src="images/resources/ny-img1.png" alt="" />
                                            </div>
                                            <div className="notification-info">
                                                <h3><a href="messages.html" title="">Jassica William</a> </h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do.</p>
                                                <span>2 min ago</span>
                                            </div>
                                        </div>
                                        <div className="notfication-details">
                                            <div className="noty-user-img">
                                                <img src="images/resources/ny-img2.png" alt=" " />
                                            </div>
                                            <div className="notification-info">
                                                <h3><a href="messages.html" title="">Jassica William</a></h3>
                                                <p>Lorem ipsum dolor sit amet.</p>
                                                <span>2 min ago</span>
                                            </div>
                                        </div>
                                        <div className="notfication-details">
                                            <div className="noty-user-img">
                                                <img src="images/resources/ny-img3.png" alt="" />
                                            </div>
                                            <div className="notification-info">
                                                <h3><a href="messages.html" title="">Jassica William</a></h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempo incididunt ut labore et dolore magna aliqua.</p>
                                                <span>2 min ago</span>
                                            </div>
                                        </div>
                                        <div className="view-all-nots">
                                            <a href="messages.html" title="">View All Messsages</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a href="#" title="" className="not-box-open">
                                    <span><img src="images/icon7.png" alt="" /></span>
                                    Notification
                                </a>
                                <div className="notification-box noti" id="notification">
                                    <div className="nt-title">
                                        <h4>Setting</h4>
                                        <a href="#" title="">Clear all</a>
                                    </div>
                                    <div className="nott-list">
                                        <div className="notfication-details">
                                            <div className="noty-user-img">
                                                <img src="images/resources/ny-img1.png" alt="" />
                                            </div>
                                            <div className="notification-info">
                                                <h3><a href="#" title="">Jassica William</a> Comment on your project.</h3>
                                                <span>2 min ago</span>
                                            </div>
                                        </div>
                                        <div className="notfication-details">
                                            <div className="noty-user-img">
                                                <img src="images/resources/ny-img2.png" alt="" />
                                            </div>
                                            <div className="notification-info">
                                                <h3><a href="#" title="">Jassica William</a> Comment on your project.</h3>
                                                <span>2 min ago</span>
                                            </div>
                                        </div>
                                        <div className="notfication-details">
                                            <div className="noty-user-img">
                                                <img src="images/resources/ny-img3.png" alt="" />
                                            </div>
                                            <div className="notification-info">
                                                <h3><a href="#" title="">Jassica William</a> Comment on your project.</h3>
                                                <span>2 min ago</span>
                                            </div>
                                        </div>
                                        <div className="notfication-details">
                                            <div className="noty-user-img">
                                                <img src="images/resources/ny-img2.png" alt="" />
                                            </div>
                                            <div className="notification-info">
                                                <h3><a href="#" title="">Jassica William</a> Comment on your project.</h3>
                                                <span>2 min ago</span>
                                            </div>
                                        </div>
                                        <div className="view-all-nots">
                                            <a href="#" title="">View All Notification</a>
                                        </div>
                                    </div>
                                </div>
                            </li>*/}
                        </ul>
                    </nav>
                    <div className="menu-btn">
                        <a href="#" onClick={handleMenuClick}>
                            <i className="fa fa-bars"></i>
                        </a>
                    </div>
                    { !apiData || apiData.length === 0 ? <div class="lds-facebook"><div>
                        </div><div></div><div></div></div> :
                        <div className="user-account">
                        <div className="user-info" onClick={handleUserClicker} style={{cursor:'pointer'}}>
                            {/* <img src={`https://robohash.org/${1}`} alt="Avatar" id="avatar"/>*/}
                            {apiData[0].photo ? (
                                <img src={`${apiData[0].photo}`} alt={`User ${apiData[0].id}`}
                                id="avatar"
                                style={{cursor:'pointer', 
                                width:'30px',
                                borderRadius: '50%',
                                background:'#63b0e3',
                                boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }} />
                            ) : (
                                <img src={`https://robohash.org/${apiData[0].id}`} 
                                id="avatar" alt="" 
                                style={{cursor:'pointer', 
                                width:'30px',
                                borderRadius: '50%',
                                background:'#63b0e3',
                                boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }} />
                                )
                            }
                            
                            {/*<div className="lds-hourglassMin" style={blockLod}></div>*/}
                            
                            <i className="la la-sort-down">Me</i>
                        </div>
                        <div style={blockStyle} ref={ref} className="user-account-settingss" id="users">
                            <h3>Online Status</h3>
                            <ul className="on-off-status">
                                <li>
                                    <div className="fgt-sec">
                                        <input type="radio" name="cc" id="c5" />
                                        <label htmlFor="c5">
                                            <span></span>
                                        </label>
                                        <small>Online</small>
                                    </div>
                                </li>
                                <li>
                                    <div className="fgt-sec">
                                        <input type="radio" name="cc" id="c6" />
                                        <label htmlFor="c6">
                                            <span></span>
                                        </label>
                                        <small>Offline</small>
                                    </div>
                                </li>
                            </ul>
                            <h3>Custom Status</h3>
                            <div className="search_form">
                                <form>
                                    <input type="text" name="search" />
                                    <button type="submit">Ok</button>
                                </form>
                            </div>
                            <h3>Setting</h3>
                            <ul className="us-links">
                                <li>Account Setting</li>
                                <li><Link style={{color:'green'}} to="/Privacy">Privacy</Link></li>
                                <li><Link style={{color:'green'}} to="/Community">Community Guidelines</Link></li>
                                <li><Link style={{color:'green'}} to="/Terms" >Terms & Conditions</Link></li>
                            </ul>
                            <h3 className="tc"><span onClick={handleLogout} style={{cursor:'pointer'}}>Logout</span></h3>
                        </div>                         
                        </div>
                    }           
                </div>
            </div>
        </header>
      </div>
        
    )
}