// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy {
    max-width: 800px;
    margin: 80px auto;
    padding: 20px;
    box-shadow: 10px 4px 58px 0 rgba(51, 30, 30, 0.2);
    border-radius: 20px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .privacy-policy h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .privacy-policy h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  
  .privacy-policy p {
    margin-bottom: 20px;
  }
  
  .privacy-policy ul {
    margin-bottom: 20px;
  }
  
  .privacy-policy li {
    margin-bottom: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/ConformitiesPolicies/Privacy.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,iDAAiD;IACjD,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".privacy-policy {\n    max-width: 800px;\n    margin: 80px auto;\n    padding: 20px;\n    box-shadow: 10px 4px 58px 0 rgba(51, 30, 30, 0.2);\n    border-radius: 20px;\n    font-family: Arial, sans-serif;\n    font-size: 16px;\n    line-height: 1.5;\n  }\n  \n  .privacy-policy h1 {\n    font-size: 36px;\n    font-weight: bold;\n    margin-bottom: 20px;\n  }\n  \n  .privacy-policy h2 {\n    font-size: 24px;\n    font-weight: bold;\n    margin-top: 40px;\n    margin-bottom: 20px;\n  }\n  \n  .privacy-policy p {\n    margin-bottom: 20px;\n  }\n  \n  .privacy-policy ul {\n    margin-bottom: 20px;\n  }\n  \n  .privacy-policy li {\n    margin-bottom: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
