import React from 'react';
import { useTranslation } from 'react-i18next';
import Header4 from '../Components/PageLayout/header4';
import Footer from '../Components/PageLayout/Footer';
import './Privacy.css';

const TermsAndConditions = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
    <Header4 />
    <div className="privacy-policy">
      <button
        onClick={() => window.history.back()}
        style={{
          padding: '10px 20px',
          borderRadius: '10px',
          background: '#3498db',
          color: '#fff',
          border: 'none',
          fontWeight: 'bold',
          cursor: 'pointer'
        }}
      >
        {t('goToHome')}
      </button>
      <h1>{t('termsAndConditions')}</h1>
      <p>{t('welcomeMessage')}</p>
      <h2>{t('acceptanceOfTerms')}</h2>
      <p>{t('acceptanceOfTermsText')}</p>
      <h2>{t('descriptionOfServices')}</h2>
      <p>{t('descriptionOfServicesText')}</p>
      <h2>{t('userConduct')}</h2>
      <p>{t('userConductText')}</p>
      <h2>{t('intellectualProperty')}</h2>
      <p>{t('intellectualPropertyText')}</p>
      <h2>{t('disclaimerOfWarranties')}</h2>
      <p>{t('disclaimerOfWarrantiesText')}</p>
      <h2>{t('limitationOfLiability')}</h2>
      <p>{t('limitationOfLiabilityText')}</p>
      <h2>{t('indemnification')}</h2>
      <p>{t('indemnificationText')}</p>
      <h2>{t('termination')}</h2>
      <p>{t('terminationText')}</p>
      <h2>{t('governingLaw')}</h2>
      <p>{t('governingLawText')}</p>
      <h2>{t('governingLawAndArbitration')}</h2>
      <p>{t('governingLawAndArbitrationText')}</p>
      <h2>{t('changesToTermsAndConditions')}</h2>
      <p>{t('changesToTermsAndConditionsText')}</p>
      <h2>{t('entireAgreement')}</h2>
      <p>{t('entireAgreementText')}</p>
      <h2>{t('contactUs')}</h2>
      <p>{t('contactUsText')}</p>
      <h2>{t('arbitration')}</h2>
      <p>{t('arbitrationText')}</p>
    </div>
    <Footer />
    </>
  );
};

export default TermsAndConditions;
