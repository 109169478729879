import React,{useContext, useEffect, useState} from "react";
import ApiContext from "../globalStore/ApiContext";
import VideoContext from "../globalStore/VideoContext";
import $ from 'jquery';

export default function PostBarModal({
    isOpen,
    onClose,
    }){
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState('');
    const [user, setUser] = useState('');
    const {data, setData} = useContext(VideoContext);
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [isLoading, setIsLoading] =  useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [error, setError] = useState(false);
    const token = localStorage.getItem('userId');
    const { apiData } = useContext(ApiContext);

    useEffect(()=>{
        if (apiData || apiData.length >= 0) {
            setUser(apiData[0].id);
            console.log("this is the user ID:", apiData[0].id)
        }
    })

    /*const handleOptimisticUpdate = (newVideo) => {
        setData(prevData => [newVideo, ...prevData]);
    }*/

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true)
        const formData = new FormData();
        formData.append('video_file', file);
        formData.append('title', title);
        formData.append('description', description);
        formData.append('category',category);
        formData.append('uploaded_by', user);
        console.log("Here the formData: ",formData);
        /*handleOptimisticUpdate({
            title,
            description,
            category,
            uploaded_by: user,
        });*/
        fetch('https://meanc4.azurewebsites.net/meanc/video/', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(newVideo => {
            console.log("success sending video:", newVideo)
            setData(prevData => {
                const index = prevData.findIndex((video) => video.id === newVideo.id);
                if (index !== -1) {
                    prevData[index] = newVideo;
                    return [...prevData];
                } else {
                    return [newVideo, ...prevData];
                }
            });
            setDescription("");
            setTitle("");
            handlePostProjectCloseClick();
        })
        .catch(error => {
            setIsLoading(false)
            setError(true)
            console.error(error)
        });
    };

    const handlePostProjectClick = () => {
        $(".post-popup.pst-pj").addClass("active");
        $(".wrapper").addClass("overlay");
    }

    const handlePostProjectCloseClick = () => {
        $(".post-popup.pst-pj").removeClass("active");
        $(".wrapper").removeClass("overlay");
    }
    const isLoadingStyle = {
        display: isLoading ? 'inline-block' : 'none'
    }
    
    return(
    <div className="post-popup pst-pj">
			  <div className="post-project">
				<h3>Post a Video</h3>
				<div className="post-project-fields">
                    <form onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-lg-12">
                <input type="text" 
                value={title} 
                onChange={event => setTitle(event.target.value)} 
                placeholder="Title"
                required />
				</div>
				<div className="col-lg-12">
                <div>
                  <select value={category} onChange={(e) => setCategory(e.target.value)}>
                    <option value="">Select a category</option>
                    <option value="Football">Football</option>
                    <option value="Basketball">Basketball</option>
                    <option value="Baseball">Baseball</option>
                    <option value="Soccer">Soccer</option>
                    <option value="Hockey">Hockey</option>
                    <option value="Boxing">Boxing</option>
                    <option value="Tennis">Tennis</option>
                    <option value="Handball">Handball</option>
                    <option value="others">Others</option>
                  </select>
                </div>
              </div>
				<div className="col-lg-12">
                <input type="text"
                value={description} 
                onChange={event => setDescription(event.target.value)}
                placeholder="Description"
                required />	
				</div>
				<div className="col-lg-12">
                <div style={{
                    border: '1px solid #e5e5e5',
                    marginTop: '20px',
                    padding: '30px',
                    background: '#efefef'
                }}>
                <input type="file" 
                      accept="video/*" 
                      onChange={event => setFile(event.target.files[0])}
                      content='Select Video'
                      required
                      style={{ 
                        border: '2px solid #ccc', 
                        borderRadius: '5px', 
                        padding: '10px',
                        backgroundColor: '#2980b9',
                        color: 'white',
                        fontWeight: 'bold'
                      }} 
                />
                <p>Upload your Video / Tape file. Max file size : 3Min</p>
                </div>  
              </div>
							<div className="col-lg-12">
                {isLoading ? <div className="lds-hourglassMin"></div> :
                <ul>
                    <li><button className="active" type="submit" value="post">Post</button></li>
                    <li><a href="#" onClick={handlePostProjectCloseClick} title="">Cancel</a></li>
                </ul>
                }
							</div>
						</div>
					</form>
				</div>
				<a href="#" onClick={handlePostProjectCloseClick} title=""><i className="la la-times-circle-o"></i></a>
			  </div>
		</div>
    )
}