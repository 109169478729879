import React, { useState } from 'react';
import axios from 'axios';
import { toBeEmpty } from '@testing-library/jest-dom/dist/matchers';

export default function LikeComponent({ postId, initialLikeCount, initiallyLiked }) {
  const [likesCount, setLikesCount] = useState(initialLikeCount);
  const [isLiked, setIsLiked] = useState(initiallyLiked);

  const handleLike = async () => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('userId');
    //console.log("this is the token", token)

    try {
      const response = await axios.post(`https://meanc4.azurewebsites.net/meanc/posts/${postId}/like/`, {}, {
        headers: {
          // Include the token in the Authorization header
          // Ensure your server is expecting a Bearer token or adjust the prefix accordingly
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        }
      });

      if (response.status === 200) {
        setIsLiked(prevIsLiked => {
          const newIsLiked = !prevIsLiked;
          // Correctly update the likes count based on the new liked state
          setLikesCount(prevLikesCount => newIsLiked ? prevLikesCount + 1 : prevLikesCount - 1);
          console.log("One like added",likesCount )
          return newIsLiked;
        });
      }
    } catch (error) {
      console.error('Error updating like:', error);
    }
  };

  return (
    <button className='replyButton' onClick={handleLike}>
      {isLiked ? 'Unlike' : 'Like'} ({likesCount})
    </button>
  );
}
