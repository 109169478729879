import React from 'react';
import TimeComponent from '../../Utils/TimeComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faSmile } from '@fortawesome/free-solid-svg-icons';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import LoadingSkeleton from './LoadingSkeleton';

const ChatWindow = ({
    showChat,
    messages,
    loadingMessages,
    messagesEndRef,
    inputValue,
    setInputValue,
    handleKeyDown,
    sendMessage,
    showEmojiPicker,
    setShowEmojiPicker,
    addEmoji,
    setShowChat,
    getUserPhoto,
    getUsername,
    apiData,
    selectedUserId
}) => {

    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate('/messenger');
        setShowChat(false);
        
    };
    return (
        <div className={`chat-column ${showChat ? 'active' : ''} ${showChat ? 'col-md-12' : 'col-md-8'}`}
            style={{ marginTop: showChat ? '0' : '12px', overflow: 'auto', maxHeight: showChat ? '100vh' : '450px', backgroundColor: 'white', border: '1px solid #ccc', borderRadius: '10px' }}>
            <h1 style={{ marginTop: '10px' }}>Live Chat between {getUsername(selectedUserId) || 'No provided'} and {getUsername(apiData[0].id)}</h1>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                <button className='buttonTypeClose' onClick={handleBackClick}>
                    <FontAwesomeIcon icon={faClose} /> <span className="button-text">Close</span>
                </button>
            </div>
            <ul id="messages">
                {loadingMessages ? (
                    Array.from(new Array(5)).map((_, index) => <LoadingSkeleton key={index} />)
                ) : (
                    messages.map((msg, index, arr) => {
                        const showDateSeparator = index === 0 || moment(arr[index - 1].timestamp).format('YYYY-MM-DD') !== moment(msg.timestamp).format('YYYY-MM-DD');
                        return (
                            <React.Fragment key={index}>
                                {showDateSeparator && (
                                    <li className="date-separator">
                                        <span>{moment(msg.timestamp).format('MMMM DD, YYYY')}</span>
                                    </li>
                                )}
                                <li className={`message ${msg.sender_id === apiData[0].id ? 'sent' : 'received'}`}>
                                    <div className='message-content'>
                                        <div className='messenger-avatar'>
                                            <img src={getUserPhoto(msg.sender_id)} alt="avatar" className='imgmessenger' />
                                        </div>
                                        <div className={`message-text ${msg.sender_id === apiData[0].id ? 'sent-text' : 'received-text'}`}>
                                            {msg.message}
                                            <div className='message-time'>
                                                <span><TimeComponent time={msg.timestamp} /></span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </React.Fragment>
                        );
                    })
                )}
                <div ref={messagesEndRef} />
            </ul>
            <div className="message-input-container">
                <button className='buttonTypeBack' onClick={handleBackClick}>
                    Back
                </button>
                <input
                    type="text"
                    className='inputType'
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="Type a message..."
                    onKeyDown={handleKeyDown}
                />
                <button className='emoji-picker-button' onClick={() => setShowEmojiPicker(true)}>
                    <FontAwesomeIcon icon={faSmile} />
                </button>
                <button className='buttonType' onClick={sendMessage}>Send</button>
            </div>
            {showEmojiPicker && (
                <div className="emoji-picker-modal">
                    <div className="emoji-picker-content">
                        <button className="close-emoji-picker" onClick={() => setShowEmojiPicker(false)}>Close</button>
                        <Picker data={data} onEmojiSelect={addEmoji} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatWindow;
