import React, { useState, useRef } from "react";
import Modal from "react-modal";
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faVolumeUp, faVolumeMute, faExpand } from '@fortawesome/free-solid-svg-icons';

Modal.setAppElement("#root");

export default function VideoModal({ isOpen, onClose, videoSrc }) {
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [progress, setProgress] = useState(0);
  const playerRef = useRef(null);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleMute = () => {
    setMuted(!muted);
  };

  const handleVolumeChange = (e) => {
    setVolume(parseFloat(e.target.value));
  };

  const handleProgress = (state) => {
    setProgress(state.played);
  };

  const handleSeek = (e) => {
    const newProgress = parseFloat(e.target.value);
    setProgress(newProgress);
    playerRef.current.seekTo(newProgress);
  };

  const customStyles = {
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '80%',
      height: '80%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
      backgroundColor: 'black',
      padding: '10px',
      overflow: 'hidden',
    },
    overlay: {
      zIndex: 9998,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <button onClick={onClose} style={{
        position: 'absolute',
        top: '20px',
        right: '20px',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        color: '#fff',
        fontSize: '2rem',
        outline: 'none',
        textShadow: '1px 1px 1px #000',
        zIndex: 10000
      }}>Close &times;</button>

      {isOpen && (
        <>
          <ReactPlayer
            ref={playerRef}
            url={videoSrc}
            playing={playing}
            muted={muted}
            volume={volume}
            width='100%'
            height='100%'
            onProgress={handleProgress}
            controls={false}  // Disable default controls
            playsinline
            style={{
              alignSelf: 'center',
            }}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                  disablePictureInPicture: true,
                },
              },
            }}
          />

          {/* Custom Controls */}
          <div style={{
            position: 'absolute',
            bottom: '10px',
            left: '10px',
            right: '10px',
            color: '#fff',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            textShadow: '1px 1px 1px #000',
            zIndex: 9999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            {/* Play/Pause Button */}
            <button onClick={handlePlayPause} style={{
              backgroundColor: 'transparent', 
              border: 'none', 
              cursor: 'pointer',
              color: '#fff',
              padding: '10px'
            }}>
              <FontAwesomeIcon icon={playing ? faPause : faPlay} size="2x" />
            </button>

            {/* Progress Bar */}
            <input
              type="range"
              min={0}
              max={1}
              step="any"
              value={progress}
              onChange={handleSeek}
              style={{
                flexGrow: 1, 
                marginLeft: '10px', 
                marginRight: '10px', 
                backgroundColor: '#555', 
                height: '8px', 
                borderRadius: '5px',
                appearance: 'none',
                cursor: 'pointer'
              }}
            />
            {/* Custom styling for range input */}
            <style>
              {`
              input[type="range"]::-webkit-slider-thumb {
                appearance: none;
                width: 15px;
                height: 15px;
                background-color: #2980b9;
                border-radius: 50%;
                cursor: pointer;
              }

              input[type="range"]::-moz-range-thumb {
                width: 15px;
                height: 15px;
                background-color: #2980b9;
                border-radius: 50%;
                cursor: pointer;
              }
              `}
            </style>

            {/* Volume Control */}
            <button onClick={handleMute} style={{
              backgroundColor: 'transparent', 
              border: 'none', 
              cursor: 'pointer', 
              color: '#fff',
              padding: '10px'
            }}>
              <FontAwesomeIcon icon={muted ? faVolumeMute : faVolumeUp} size="2x" />
            </button>
            <input
              type="range"
              min={0}
              max={1}
              step="any"
              value={volume}
              onChange={handleVolumeChange}
              style={{
                width: '100px', 
                marginLeft: '10px',
                backgroundColor: '#555',
                height: '8px',
                borderRadius: '5px',
                appearance: 'none',
                cursor: 'pointer'
              }}
            />

            {/* Fullscreen (optional) */}
            <button style={{
              backgroundColor: 'transparent', 
              border: 'none', 
              cursor: 'pointer', 
              color: '#fff',
              padding: '10px'
            }}>
              <FontAwesomeIcon icon={faExpand} size="2x" />
            </button>
          </div>
        </>
      )}

      <div style={{
        position: 'absolute',
        bottom: '10px',
        right: '10px',
        color: '#fff',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        textShadow: '1px 1px 1px #000',
        zIndex: 9999
      }}>
        MeanCSports
      </div>
    </Modal>
  );
}
