import React, { useEffect } from 'react';
import moment from 'moment';

export default function TimeComponent({ time }) {
    useEffect(() => {
        // Update the relative time thresholds
        moment.updateLocale('en', {
            relativeTime: {
                future: "in %s",
                past: "%s ago",
                s: '%ds',  // seconds
                ss: '%ds', // seconds if more than one, ensures it is always using seconds
                m: "1m",   // one minute
                mm: "%dm", // %d minutes
                h: "1h",   // one hour
                hh: "%dh", // %d hours
                d: "1day",   // one day
                dd: "%dd", // %d days
                M: "1mo",  // one month
                MM: "%dmo",// %d months
                y: "1y",   // one year
                yy: "%dy"  // %d years
            }
        });
    }, []); // Empty dependency array ensures this runs once on mount

    return <span>{moment(time).fromNow(true)}</span>;
}

