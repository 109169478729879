import React,{ useState, useEffect } from 'react';
import Feedback from '../Components/Feedback';
import Header2 from '../Components/PageLayout/Header2';
import Footer from '../Components/PageLayout/Footer';


export default function FeedbackHome(){
    return (
        <>
        <Header2 />
            <Feedback />
        <Footer />
        </>
    )
}