import React, { useEffect, useState } from "react";
import Slider from 'react-slick';
import $ from 'jquery';


export default function TopProfiles(){
    
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <span className="slick-previous"></span>,
        nextArrow: <span className="slick-nexti"></span>,
        autoplay: true,
        dots: false,
        autoplaySpeed: 2000,
        responsive: [
        {
            breakpoint: 1200,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            },
        },
        {
            breakpoint: 991,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            },
        },
        {
            breakpoint: 768,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            },
        },
        ],
    };
    return(
        <div className="top-profiles">
				<div className="pf-hd">
					<h3>Top Profiles</h3>
						<i className="la la-ellipsis-v"></i>
				</div>
                {isLoaded && (
                    <Slider className="profiles-slider" {...settings}>
                     <div className="user-profy">
													<img src="images/resources/user1.png" alt="" />
													<h3>John Doe</h3>
													<span>Graphic Designer</span>
													<ul>
														<li><a href="#" title="" className="followw">Follow</a></li>
														<li><a href="#" title="" className="envlp"><img src="images/envelop.png" alt="" /></a></li>
														<li><a href="#" title="" className="hire">hire</a></li>
													</ul>
													<a href="#" title="">View Profile</a>
					 </div>
					<div className="user-profy">
													<img src="images/resources/user2.png" alt="" />
													<h3>John Doe</h3>
													<span>Graphic Designer</span>
													<ul>
														<li><a href="#" title="" className="followw">Follow</a></li>
														<li><a href="#" title="" className="envlp"><img src="images/envelop.png" alt="" /></a></li>
														<li><a href="#" title="" className="hire">hire</a></li>
													</ul>
													<a href="#" title="">View Profile</a>
					</div>
					<div className="user-profy">
													<img src="images/resources/user3.png" alt="" />
													<h3>John Doe</h3>
													<span>Graphic Designer</span>
													<ul>
														<li><a href="#" title="" className="followw">Follow</a></li>
														<li><a href="#" title="" className="envlp"><img src="images/envelop.png" alt="" /></a></li>
														<li><a href="#" title="" className="hire">hire</a></li>
													</ul>
													<a href="#" title="">View Profile</a>
					</div>
					<div className="user-profy">
													<img src="images/resources/user1.png" alt="" />
													<h3>John Doe</h3>
													<span>Graphic Designer</span>
													<ul>
														<li><a href="#" title="" className="followw">Follow</a></li>
														<li><a href="#" title="" className="envlp"><img src="images/envelop.png" alt="" /></a></li>
														<li><a href="#" title="" className="hire">hire</a></li>
													</ul>
													<a href="#" title="">View Profile</a>
					</div>
					<div className="user-profy">
													<img src="images/resources/user2.png" alt=" " />
													<h3>John Doe</h3>
													<span>Graphic Designer</span>
													<ul>
														<li><a href="#" title="" className="followw">Follow</a></li>
														<li><a href="#" title="" className="envlp"><img src="images/envelop.png" alt="" /></a></li>
														<li><a href="#" title="" className="hire">hire</a></li>
													</ul>
													<a href="#" title="">View Profile</a>
					</div>
					<div className="user-profy">
													<img src="images/resources/user3.png" alt="" />
													<h3>John Doe</h3>
													<span>Graphic Designer</span>
													<ul>
														<li><a href="#" title="" className="followw">Follow</a></li>
														<li><a href="#" title="" className="envlp"><img src="images/envelop.png" alt="" /></a></li>
														<li><a href="#" title="" className="hire">hire</a></li>
													</ul>
								<a href="#" title="">View Profile</a>
					</div>
                    </Slider>
                )}
		</div>
    )
}