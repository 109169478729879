import React from 'react';
import Flag from 'react-world-flags';

const CountryFlag = ({ countryCode }) => {
  return (
    <div style={{marginLeft:'40px', marginTop:'-45px'}}>
        <Flag code={countryCode} style={{ width: '42px', height: '32px',boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }} />
    </div>
  );
};

export default CountryFlag;
