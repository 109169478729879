import React, { useEffect, useState } from "react";
import Footer from '../Components/PageLayout/Footer';
import VideoContext from "../Components/globalStore/VideoContext";
import HomePage from './HomePage';
import Header2 from "../Components/PageLayout/Header2";

function Appsub() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetch("https://meanc4.azurewebsites.net/meanc/uservideos/", {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Authentication failed');
      }
      return response.json();
    })
    .then(da => {
      console.log("video data from apuB", da)
      setData(da);
    })
    .catch(error => {
      console.log("Error fetching videos:", error);
      // If authentication fails, redirect to login
      window.location.href = '/';
    });
  }, []);

  console.log("Here data video from Appsub:", data);

  return (
    <VideoContext.Provider value={{ data, setData }}>
      <>
        <Header2 />
          <HomePage />
        <Footer />
      </>
    </VideoContext.Provider>
  );
}

export default Appsub;
