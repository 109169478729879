import React, {useState} from "react";



const Login = () =>{
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const handleSubmit=(e)=>{
        e.preventDefault()
        console.log(email)
        console.log(password)
        const item = {email, password}
        fetch('https://meanc4.azurewebsites.net/meanc/login/',{
            method:'POST',
            headers:{
                "content-type":"application/json"
            },
            body:JSON.stringify(item)
        })
        .then(response=>response.json())
        .then(data=>{
            console.log(data.token)
            const token = data.token;
            localStorage.setItem('userId',token)
            window.location.href = '/dashbord';
        })
        .catch(error=>{
            console.log("This is Error:", error)
        })

    }

    return(
 
        <div className="containers">
                <form onSubmit={handleSubmit}>
                    <h1>Login</h1>
                    <label htmlFor="email">Email<span style={{color: "red"}}>*</span></label>
                    <input type="email" 
                     value={email} 
                     placeholder="Enter Email" 
                     id="email"
                     onChange={(e)=>setEmail(e.target.value)}
                     required />
                    <label htmlFor="psw"><b>Password</b></label>
                    <input type="password"
                     value={password}
                     onChange={(e)=> setPassword(e.target.value)}
                     id="psw"
                     placeholder="Enter Password" 
                     required />   
                    <button type="submit" className="btn">Login</button>
                    <hr />    
                </form>
    
        </div>
    )
}

export default Login;