import React, { useState } from 'react';
import ReactPlayer from 'react-player';


export default function VideoTag({ videoSrc }) {
  const [isLoading, setIsLoading] = useState(true);

  const handleReady = () => {
    setIsLoading(false);
  };

  const handleBuffer = () => {
    setIsLoading(true);
  };

  return (
    <div className="myVideo">
      {/*{isLoading && <div>Loading...</div>}
        <ReactPlayer
        url={videoSrc}
        controls={true}
        onReady={handleReady}
        onBuffer={handleBuffer}
    />*/}
      <video controls src={videoSrc} />
    </div>
  );
}
