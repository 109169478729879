import React, { useContext } from 'react';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaInstagram, FaEnvelope } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import ApiContext from '../globalStore/ApiContext';
import { Col } from 'react-bootstrap';

const ReferralLinkGenerator = () => {
  const { apiData } = useContext(ApiContext);
  const { t } = useTranslation();
  const userId = apiData.length ? apiData[0].id : ''; // Adjust this to get the correct user ID
  console.log("this is the user information", apiData);
  const referralLink = `https://meanc.net?referrer=${userId}`;

  const handleInstagramShare = () => {
    const instagramUrl = `https://www.instagram.com/?url=${referralLink}`;
    window.open(instagramUrl, '_blank');
  };

  const handleGmailShare = () => {
    const subject = t("joinMEANC");
    const body = `${t("joinMEANC")}: ${referralLink}`;
    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className='referral'>
      <h3>{t('hi_user')} {apiData[0].username? apiData[0].username:'n/A'}, {t('shareReferralLink')}</h3>
      <input type="text" value={referralLink} readOnly />
      
      <div>
        <CopyToClipboard text={referralLink} style={{color:'white'}}>
          <button>{t('copyLink')}</button>
        </CopyToClipboard>
      </div>

      <div className="share-buttons">
        <FacebookShareButton url={referralLink} quote={t("joinMEANC")}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        
        <TwitterShareButton url={referralLink} title={t("joinMEANC")}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <WhatsappShareButton url={referralLink} title={t("joinMEANC")}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>

        <button onClick={handleGmailShare} className="icon-button">
          <FaEnvelope size={32} className="icon" />
        </button>
        
        <button onClick={handleInstagramShare} className="icon-button">
          <FaInstagram size={32} className="icon" />
        </button>
      </div>
    </div>
  );
};

export default ReferralLinkGenerator;
