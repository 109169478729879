import React from "react";
import ProfilePostDetail from "./ProfilePostDetail";
import { useTranslation } from 'react-i18next';

export default function Profiles () {

    const { t } = useTranslation();
    return (
        <>
        <section className="companies-info">
			<div className="container">
				<div className="company-title">
					<h3 style={{marginLeft:'15px', marginRight:'15px'}}>{t('listOfRecruiters')}</h3>
                    {/*
                    <div className="search-sec">
                        <div className="container">
                            <div className="search-box">
                                <form>
                                    <input type="text" name="search" placeholder="Search keywords" />
                                    <button type="submit">Search</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    */}
				</div>
				<div className="companies-list">
					<div className="row">
                        <ProfilePostDetail />
					</div>
				</div>
			</div>
		</section>
        </>
    )
}