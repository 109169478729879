import React, { useEffect, useContext, useState } from 'react';
import ApiContext from '../globalStore/ApiContext';

export default function ProtectedPage() {
  const [error, setError] = useState('');
  const { apiData, setApiData } = useContext(ApiContext); // Assuming setApiData is available in context

  useEffect(() => {
    fetch('https://meanc4.azurewebsites.net/meanc/meanc/users/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include' // Include credentials in the request
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      console.log("User data fetched from API:", data);
      setApiData(data); // Set the fetched data to apiData
    })
    .catch(error => {
      console.error("Error fetching user data:", error);
      setError('Failed to fetch user data.');
    });
  }, [setApiData]);

  const handleLogout = (e) => {
    e.preventDefault();
    fetch('http://127.0.0.1:8000/meanc/logout/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include' // Include credentials in the request
    })
    .then(response => {
      if (response.ok) {
        // Clear local storage and redirect to the login page
        localStorage.removeItem('userId');
        localStorage.removeItem('user_id');
        window.location.href = '/';
        console.log('Logout successful');
      } else {
        throw new Error('Logout failed');
      }
    })
    .catch(error => {
      console.error("Error during logout:", error);
    });
  };

  // Conditional rendering based on apiData presence
  if (!apiData || apiData.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h3>Protected Page of {apiData[0].username}</h3>
      <div className="user-picy">
        {apiData[0].photo ? (
          <img src={`${apiData[0].photo}`} alt={`User ${apiData[0].id}`} id="avatar" 
            style={{ cursor:'pointer', width:'60px', borderRadius:'10px' }} />
        ) : (
          <img src={`https://robohash.org/${apiData[0].id}`} 
            id="avatar" alt="" style={{ cursor:'pointer', width:'60px' }} />
        )}
      </div>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
}
