import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function SimpleLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    const item = { email, password };

    fetch('http://127.0.0.1:8000/meanc/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(item),
      credentials: 'include' // Ensure cookies are included in the request
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      console.log('Login successful:', data);
      console.log('Cookies after login:', document.cookie);
      navigate('/protected');
    })
    .catch(error => {
      console.error('Error:', error);
      setError('An error occurred during login.');
    });
  };

  useEffect(() => {
    console.log('Auth token on SimpleLogin render:', document.cookie);
  }, []);

  return (
    <div>
      <h3>Login</h3>
      <form onSubmit={handleLogin}>
        <input
          type="email"
          value={email}
          placeholder="Enter Email"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          value={password}
          placeholder="Enter Password"
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Login</button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
}
