import React, { useState, useEffect, useContext } from 'react';
import ApiContext from '../globalStore/ApiContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faReply } from '@fortawesome/free-solid-svg-icons';
import TimeComponent from '../../Utils/TimeComponent';
import CommentLikeDislikeButton from './CommentLikeDislikeButton'; // Import the new component
import formatNumber from '../../Utils/formatNumber';
import TruncateMessage from '../../Utils/truncateMessage';

export default function CommentButton({ postId }) {
  const [commentText, setCommentText] = useState('');
  const [comments, setComments] = useState([]);
  const [commentCount, setCommentCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [visibleCount, setVisibleCount] = useState(3); // Initialize visible comments count to 3
  const [replyTexts, setReplyTexts] = useState({});
  const [showReplyForm, setShowReplyForm] = useState(null);
  const [showMoreReplies, setShowMoreReplies] = useState({});
  const { apiData } = useContext(ApiContext);

  useEffect(() => {
    const controller = new AbortController();

    const fetchComments = async () => {
      try {
        const response = await fetch(`https://meanc4.azurewebsites.net/meanc/comment/com_by_videos/?video=${postId}`, { signal: controller.signal });

        if (response.ok) {
          const data = await response.json();
          const organizedComments = organizeCommentsWithReplies(data);
          setComments(organizedComments);
          setCommentCount(data.length);
        } else {
          console.log('Error fetching comments:', response.status);
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Request aborted:', error);
        } else {
          console.error('Error fetching comments:', error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchComments();

    return () => {
      controller.abort();
    };
  }, [postId]);

  const organizeCommentsWithReplies = (comments) => {
    const commentMap = new Map();

    comments.forEach(comment => {
      comment.replies = [];
      commentMap.set(comment.id, comment);
    });

    const organizedComments = [];
    comments.forEach(comment => {
      if (comment.parent) {
        const parentComment = commentMap.get(comment.parent);
        if (parentComment) {
          parentComment.replies.push(comment);
        }
      } else {
        organizedComments.push(comment);
      }
    });

    return organizedComments;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (commentText !== '') {
      const newComment = { text: commentText, user: apiData[0].id, username: apiData[0].username, replies: [] };

      setComments([newComment, ...comments]);
      setCommentCount(commentCount + 1);
      setCommentText('');

      try {
        const response = await fetch('https://meanc4.azurewebsites.net/meanc/comment/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          },
          body: JSON.stringify({
            text: newComment.text,
            userid: newComment.user,
            video: postId,
          }),
        });

        if (!response.ok) {
          console.log('Error posting comment:', response.status);
          setComments(comments);
          setCommentCount(commentCount - 1);
        }
      } catch (error) {
        console.error('Error posting comment:', error);
        setComments(comments);
        setCommentCount(commentCount - 1);
      }
    }
  };

  const handleReplySubmit = async (event, parentComment) => {
    event.preventDefault();
    const replyText = replyTexts[parentComment.id];
    if (replyText && replyText !== '') {
      const newReply = { text: replyText, user: apiData[0].id, username: apiData[0].username, parent: parentComment.id, replies: [] };

      const updatedComments = comments.map(comment => {
        if (comment.id === parentComment.id) {
          return { ...comment, replies: [newReply, ...comment.replies] };
        }
        const updatedReplies = comment.replies.map(reply => {
          if (reply.id === parentComment.id) {
            return { ...reply, replies: [newReply, ...reply.replies] };
          }
          return reply;
        });
        return { ...comment, replies: updatedReplies };
      });

      setComments(updatedComments);
      setReplyTexts({ ...replyTexts, [parentComment.id]: '' });
      setShowReplyForm(null);

      try {
        const response = await fetch('https://meanc4.azurewebsites.net/meanc/comment/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          },
          body: JSON.stringify({
            text: newReply.text,
            userid: newReply.user,
            video: postId,
            parent: parentComment.id
          }),
        });

        if (!response.ok) {
          console.log('Error posting reply:', response.status);
          const revertedComments = comments.map(comment => {
            if (comment.id === parentComment.id) {
              return { ...comment, replies: comment.replies.filter(reply => reply !== newReply) };
            }
            const revertedReplies = comment.replies.map(reply => {
              if (reply.id === parentComment.id) {
                return { ...reply, replies: reply.replies.filter(r => r !== newReply) };
              }
              return reply;
            });
            return { ...comment, replies: revertedReplies };
          });

          setComments(revertedComments);
        }
      } catch (error) {
        console.error('Error posting reply:', error);
        const revertedComments = comments.map(comment => {
          if (comment.id === parentComment.id) {
            return { ...comment, replies: comment.replies.filter(reply => reply !== newReply) };
          }
          const revertedReplies = comment.replies.map(reply => {
            if (reply.id === parentComment.id) {
              return { ...reply, replies: reply.replies.filter(r => r !== newReply) };
            }
            return reply;
          });
          return { ...comment, replies: revertedReplies };
        });

        setComments(revertedComments);
      }
    }
  };

  const renderReplies = (replies, parentId) => {
    const limitedReplies = showMoreReplies[parentId] ? replies : replies.slice(0, 3);
    return (
      <>
        {limitedReplies.map(reply => (
          <div key={reply.id} className="reply-item">
            <div className="comment-header">
              <img
                src={reply.photo ? reply.photo : `https://robohash.org/${reply.userid}`}
                alt=""
                style={{
                  cursor: 'pointer',
                  width: '20px',
                  margin: '10px',
                  borderRadius: '50%',
                  background: 'white',
                  boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)',
                }}
              />
              <div className="comment-user">{reply.username}</div>
              <span style={{ display: 'flex', alignItems: 'center'}}>
                <img src="images/clock.png" alt="" style={{marginRight: '-5px'}}/>
                <TimeComponent time={reply.comment_date} />
              </span>
            </div>
            {/* <div className="comment-text" style={{ marginTop: '-15px', marginLeft: '40px' }}>{reply.text}</div> */}
            <div className="comment-text" style={{ marginTop: '-15px', marginLeft: '40px' }}>
                <TruncateMessage message={reply.text} />
            </div>

            <div className="comment-actions" style={{ marginLeft: '40px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
              <FontAwesomeIcon
                icon={faReply}
                style={{ cursor: 'pointer', marginRight: '10px' }}
                onClick={() => setShowReplyForm(reply.id)} // Show reply form on click
              />
              <CommentLikeDislikeButton commentId={reply.id} /> {/* Like/Dislike buttons for replies */}
            </div>
            {showReplyForm === reply.id && (
              <div className="reply-form" style={{ marginLeft: '40px', marginTop: '10px' }}>
                <form onSubmit={(e) => handleReplySubmit(e, reply)}>
                  <input
                    type="text"
                    placeholder="Write a reply..."
                    value={replyTexts[reply.id] || ''}
                    onChange={(e) => setReplyTexts({ ...replyTexts, [reply.id]: e.target.value })}
                  />
                  <button type="submit">Reply</button>
                </form>
              </div>
            )}
            <div className="comment-replies">
              {reply.replies && reply.replies.length > 0 && renderReplies(reply.replies, reply.id)}
            </div>
          </div>
        ))}
        {replies.length > 3 && (
          <button className='buttonMore' onClick={() => setShowMoreReplies({ ...showMoreReplies, [parentId]: !showMoreReplies[parentId] })}>
            {showMoreReplies[parentId] ? "Show less replies" : "Show more replies"}
          </button>
        )}
      </>
    );
  };

  const renderComments = (comments) => {
    return comments.slice(0, visibleCount).map(comment => ( // Slice by visibleCount
      <div key={comment.id} className="comment-item">
        <div className="comment-header">
          <img
            src={comment.photo ? comment.photo : `https://robohash.org/${comment.userid}`}
            id="avatar"
            alt=""
            style={{
              cursor: 'pointer',
              width: '25px',
              margin: '10px',
              borderRadius: '50%',
              background: 'white',
              boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)',
            }}
          />
          <div className="comment-user">{comment.username}</div>
          <span style={{ display: 'flex', alignItems: 'center'}}>
            <img src="images/clock.png" alt="" style={{marginRight: '-5px'}}/>
            <TimeComponent time={comment.comment_date} />
          </span>
        </div>
        {/* <div className="comment-text" style={{ marginTop: '-15px', marginLeft: '40px' }}>{comment.text}</div> */}
        <div className="comment-text" style={{ marginTop: '-15px', marginLeft: '40px' }}>
            <TruncateMessage message={comment.text} />
        </div>
        <div className="comment-actions" style={{ marginLeft: '40px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon
            icon={faReply}
            style={{ cursor: 'pointer', marginRight: '10px' }}
            onClick={() => setShowReplyForm(comment.id)} // Show reply form on click
          />
          <CommentLikeDislikeButton commentId={comment.id} /> {/* Like/Dislike buttons for comments */}
        </div>
        {showReplyForm === comment.id && (
          <div className="reply-form" style={{ marginLeft: '40px', marginTop: '10px' }}>
            <form onSubmit={(e) => handleReplySubmit(e, comment)}>
              <input
                type="text"
                placeholder="Write a reply..."
                value={replyTexts[comment.id] || ''}
                onChange={(e) => setReplyTexts({ ...replyTexts, [comment.id]: e.target.value })}
              />
              <button type="submit">Reply</button>
            </form>
          </div>
        )}
        <div className="comment-replies">
          {comment.replies && comment.replies.length > 0 && renderReplies(comment.replies, comment.id)}
        </div>
      </div>
    ));
  };
  
  return (
    <>
      <ul>
        <li>
          <p style={{ margin: "10px 34px", 
                      float: 'right', 
                      color: 'black', 
                      backgroundColor: 'white', 
                      borderRadius: '8px', 
                      boxShadow: '0 2px 5px rgba(0,0,0,0.1)', 
                      padding: '5px 5px',
                      marginRight:'5px' }}>
            <FontAwesomeIcon icon={faComments} /> comments ({formatNumber(commentCount)})
          </p>
        </li>
      </ul>
      <div className="comment-section">
        <div className="comment-sec" style={{ overflow: 'auto', maxHeight: '250px' }}>
          {isLoading ? (
            <div>Loading comments...</div>
          ) : (
            renderComments(comments)
          )}
          {visibleCount < comments.length && (
            <button className='buttonMore' onClick={() => setVisibleCount(visibleCount + 3)}>
              Show more
            </button>
          )}
        </div>
        <div className="post-comment">
          <div className="comment_box">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Write a comment..."
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
              />
              <button type="submit">Send</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
