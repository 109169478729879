import React, { useEffect, useState } from 'react';
import ApiContext from './Components/globalStore/ApiContext';
import { SearchProvider } from './Components/globalStore/SearchContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles/App.css';
import './Styles/style.css';
import './Styles/responsive.css';
import ApiRoutes from './Routes/ApiRoutes';
import { DataProvider } from './Context/DataProvider';

export default function App() {
  const [apiData, setApiData] = useState([]);
  const token = localStorage.getItem("userId");

  useEffect(() => {
    if (token) {
      fetch("https://meanc4.azurewebsites.net/meanc/users/", {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
      })
        .then(response => response.json())
        .then(da => {
          setApiData(da);
          console.log("Here User data from App:", da);
        })
        .catch(error => {
          console.log("here an error:", error);
        });
    }
  }, [token]);

  return (
    <DataProvider>
      <ApiContext.Provider value={{ apiData, setApiData }}>
        <SearchProvider>
          <ApiRoutes />
        </SearchProvider>
      </ApiContext.Provider>
    </DataProvider>
  );
}
