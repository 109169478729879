import React from "react";

export default function DescriptionPost({description}){
    return (
        <> 
         <div className="description-title">
            <h3>{description}</h3>
         </div>
        </>
    )
}