import React from "react";
import Header from "../Components/Others/Header";
import Footer from "../Components/PageLayout/Footer";
import Header2 from "../Components/PageLayout/Header2";
import TalentVideos from "../Components/Talents/TalentVideos";


export default function Talents(){
    
    
    return(
        <>
        <Header2 />
            <TalentVideos />
        <Footer />
        </>
    ) 
}