import React, { useRef, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import Hls from 'hls.js';

export default function VideoPlayer({ src }) {
    const playerRef = useRef(null);
    const videoRef = useRef(null); // Reference for metadata loading
    const [autoPlay, setAutoPlay] = useState(false);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.addEventListener('loadedmetadata', () => {
                console.log(`Video Dimensions: ${videoElement.videoWidth} x ${videoElement.videoHeight}`);
            });

            videoElement.src = src; // Set the source to load metadata
        }

        const player = playerRef.current;
        if (Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(src);
            hls.attachMedia(player.wrapper.getElementsByTagName('video')[0]);
            hls.on(Hls.Events.MANIFEST_PARSED, function() {
                if (autoPlay) {
                    player.wrapper.getElementsByTagName('video')[0].play();
                }
            });

            return () => {
                hls.destroy();
                videoElement.removeEventListener('loadedmetadata', () => {});
            };
        } else if (playerRef.current.canPlayType('application/vnd.apple.mpegurl')) {
            playerRef.current.src = src;
            if (autoPlay) {
                playerRef.current.play();
            }
        }
    }, [src, autoPlay]);

    return (
        <div>
            <video ref={videoRef} style={{ display: 'none' }} />  {/* Hidden video element for metadata */}
            <ReactPlayer
                ref={playerRef}
                url={src}
                controls={true}
                playing={autoPlay}
                width='100%'
                height='auto'
                pip={true}
                className='react-player'
                config={{
                    file: {
                        forceHLS: true,
                        hlsOptions: {
                            debug: true,
                        },
                        hlsVersion: '1.0.7'
                    }
                }}
            />
        </div>
    );
}
