import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';

const DynamicVideoPlayer = ({ src, autoPlay = false }) => {
    const videoRef = useRef(null);
    const [aspectRatio, setAspectRatio] = useState('56.25%'); // Default aspect ratio
    console.log("Here the url from the DynamicVideoPlayer: ", src)
    useEffect(() => {
        const video = videoRef.current;
        let hls; // Declare hls at a higher scope to ensure availability

        const onMetaDataLoaded = () => {
            // Calculate and update aspect ratio state
            const ratio = (video.videoHeight / video.videoWidth) * 100;
            setAspectRatio(`${ratio}%`);
        };

        // Attach metadata loaded event listener
        video.addEventListener('loadedmetadata', onMetaDataLoaded);

        if (Hls.isSupported()) {
            hls = new Hls(); // Initialize HLS in the correct scope
            hls.loadSource(src);
            hls.attachMedia(video);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                if (autoPlay) {
                    video.play();
                }
            });
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = src;
            if (autoPlay) {
                video.play();
            }
        }

        return () => {
            // Clean up
            video.removeEventListener('loadedmetadata', onMetaDataLoaded);
            if (hls) { // Check if hls was created
                hls.destroy();
            }
        };
    }, [src, autoPlay]);

    return (
        <div className="player-wrapper" style={{ paddingTop: aspectRatio }}>
            <video ref={videoRef} controls style={{ width: '100%', height: '100%' }} />
        </div>
    );
};

export default DynamicVideoPlayer;
