import React, { useContext, useEffect, useState } from "react";
import { Carousel, Modal, Button, Form, Spinner } from 'react-bootstrap';
import ApiContext from "../Components/globalStore/ApiContext";
import VideoContext from "../Components/globalStore/VideoContext";
import PostBar from "../Components/Posts/PostBar";
import PostBarModal from "../Components/Modal/PostBarModel";
import HomeUserProfile from "../Components/HomeComponents/HomeUserProfile";
import TopProfiles from "../Components/Others/TopProfiles";
import HomePostTop from "../Components/HomeComponents/HomePostTop";
import HomeRightSide1 from "../Components/HomeComponents/HomeRightSide1";
import HomeRightSide2 from "../Components/HomeComponents/HomeRightSide2";
import HomePostFeed from "../Components/HomeComponents/HomePostFeed";
import { DataContext } from "../Context/DataProvider";
import CustomCarousel from "../Utils/CustomCarousel";
import { useTranslation } from 'react-i18next';

export default function HomePage() {
  const { apiData, setApiData } = useContext(ApiContext);
  const { categories } = useContext(DataContext);
  const { data, setData } = useContext(VideoContext);
  const [isLoading, setIsLoading] = useState(false);
  const [typeSpecificData, setTypeSpecificData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
  });

  const { t } = useTranslation();

  const fetchTypeSpecificData = async () => {
    const userType = localStorage.getItem("userType"); // Assuming you have user-type stored in localStorage
    let url = "";

    if (userType === "player") {
      url = "https://meanc4.azurewebsites.net/meanc/players/";
    } else if (userType === "recruiter") {
      url = "https://meanc4.azurewebsites.net/meanc/recruiters/";
    }

    if (url) {
      try {
        const response = await fetch(url, {
          credentials: 'include', // Include cookies in the request
        });
        const typeData = await response.json();
        setTypeSpecificData(typeData);
        console.log(`User's type-specific information for ${userType}`, typeData);
      } catch (error) {
        console.error(`Error fetching type-specific data from ${url}: ${error}`);
      }
    }
  };

  const checkUserInfo = () => {
    const user = apiData.find(user => user.id === parseInt(localStorage.getItem('currentId')));
    if (user && (!user.first_name || !user.last_name)) {
      setShowModal(true);
      setFormData({
        first_name: user.first_name || '',
        last_name: user.last_name || ''
      });
    }
  };

  const handleModalSave = async () => {
    setIsLoading(true); // Start loading spinner
    const userId = localStorage.getItem('currentId');
    const token = localStorage.getItem('userId');
    try {
      const response = await fetch(`https://meanc4.azurewebsites.net/meanc/users/${userId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      setApiData(prevApiData => prevApiData.map(user => user.id === data.id ? data : user));
      setShowModal(false);
    } catch (error) {
      console.error('Error updating user information:', error);
    }
    setIsLoading(false); // Stop loading spinner
  };

  useEffect(() => {
    if (apiData && apiData.length > 0) {
      checkUserInfo();
    }
  }, [apiData]);

  useEffect(() => {
    if (!typeSpecificData) {
      fetchTypeSpecificData();
    }
  }, [typeSpecificData]);

  if (!apiData || apiData.length === 0) {
    return (
      <div className="process-comm">
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <main>
        <div className="main-section">
          <div className="container">
            <div className="main-section-data">
              <div className="row">
                <div className="col-lg-3 col-md-4 pd-left-none no-pd">
                  <div className="main-left-sidebar no-margin">
                    <HomeUserProfile />
                  </div>
                </div>
                <div className="col-lg-6 col-md-8 no-pd">
                  <div className="main-ws-sec">
                    {}
                    <HomePostTop />
                    <div className="posts-section">
                      <HomePostFeed />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 pd-right-none no-pd">
                  <div className="right-sidebar">
                    <HomeRightSide1 />
                    <HomeRightSide2 />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Modal show={showModal} onHide={() => setShowModal(false)} style={{ marginTop: '96px' }} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('updateInformation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label htmlFor="first_name" style={{ marginBottom: '10px' }}>{t('firstName')}</Form.Label>
              <Form.Control
                type="text"
                id="first_name"
                value={formData.first_name}
                onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="last_name" style={{ marginBottom: '10px', marginTop: '10px' }}>{t('lastName')}</Form.Label>
              <Form.Control
                type="text"
                id="last_name"
                value={formData.last_name}
                onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t('skip')}
          </Button>
          <Button variant="primary" onClick={handleModalSave} disabled={isLoading}>
            {isLoading ? <Spinner animation="border" size="sm" /> : t('saveChanges')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
