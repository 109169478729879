import React from "react";

export default function LoadingComponent(){

    return (
        <>
         <div className="process-comm">
            <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
                {/*<div classNameName="lds-hourglass"></div>*/}
            </div>
          </div>
        </>
    )
}