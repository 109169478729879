import React from "react";
import SignupUser from "./SignupUser";
import { useTranslation } from 'react-i18next';

export default function SignUp({ referrerId }) {
    const { t } = useTranslation();
    console.log("here the referral id in the signUp", referrerId);
    return (
        <>
            <div className="sign_in_sec"
                style={{ color: 'black', 
                background: 'white',
                border: '1px solid green',
                backgroundImage: 'url("https://www.transparenttextures.com/patterns/cubes.png")' }} 
                id="tab-2">
                <h3 style={{ margin: '15px' }}>{t('register_here')}</h3>
                <div className="signup-tab" style={{ textAlign: 'center', padding: '10px', border: 'None', backgroundImage: 'url("https://www.transparenttextures.com/patterns/cubes.png")' }}>
                    <h2 style={{ marginTop: '10px', marginBottom: "-10px" }}>
                        {t('join_us_message_short')}
                    </h2>
                </div>
                <SignupUser referrerId={referrerId} />
            </div>
        </>
    );
}
