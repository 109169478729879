import React, { useState, useContext } from 'react';
import ApiContext from '../Components/globalStore/ApiContext';
import { DataContext } from '../Context/DataProvider';

export default function PostComment({ addNewPost }) {
  const [feedback, setFeedback] = useState('');
  const [category, setCategory] = useState(''); // State to hold the selected category
  const { apiData } = useContext(ApiContext);
  const { categories } = useContext(DataContext);
  const [isInvalid, setIsInvalid] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!feedback.trim()) {
      setIsInvalid(true);
      return;
    }

    try {
      const response = await fetch('https://meanc4.azurewebsites.net/meanc/posts/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content: feedback, title: "Feedback", category, user: apiData[0].id }),
      });
      const json = await response.json();
      addNewPost(json);  // Call the function passed from the parent to update the main data state
      setFeedback('');
      setCategory(''); // Reset category after successful submission
      setIsInvalid(false);
    } catch (error) {
      console.log('Error:', error);
    }
  };

  return (
    <div className="post-comment">
      <div className="comment_box">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Share your favorite sport and why you love it—let's discuss!"
            value={feedback}
            onChange={(e) => {
              setFeedback(e.target.value);
              if (isInvalid) setIsInvalid(false);
            }}
            maxLength={200}
            style={{ borderColor: isInvalid ? 'red' : 'initial' }}
            required
          />
          {/* Category selection dropdown */}
          <select
            value={category}
            onChange={e => setCategory(e.target.value)}
            style={{ display: 'block', padding:'10px' }}
            required
          >
            <option value="">Select Category</option>
            {categories.map(cat => (
              <option key={cat.id} value={cat.id}>{cat.name}</option>
            ))}
          </select>
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
}
