import React, { useState, useEffect } from "react";
import GoogleButton from "./SignGoogle3";
import FacebookButton from "./SignFacebook";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom'; // Import useLocation

export default function SignIn() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [userType, setUserType] = useState('');
    const [currentId, setCurrentId] = useState('');
    const [error, setError] = useState('');
    const location = useLocation(); // Initialize useLocation

    useEffect(() => {
        if (location.state && location.state.email) {
            setEmail(location.state.email);
        }
    }, [location.state]);

    const handleLogin = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const item = { email, password };

        fetch('https://meanc4.azurewebsites.net/meanc/login/', {
            method: 'POST',
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(item)
        })
        .then(response => response.json())
        .then(data => {
            setIsLoading(false);
            console.log(data.token);
            console.log("This is my info on SignIN: ", data);
            let currenIdInfo = data.user_id;
            let tokener = data.token;
            let userTyper = data.user_type;
            if (!tokener) {
                setError(data.error);
                setEmail('');
                setPassword('');
            } else {
                setToken(tokener);
                setUserType(userTyper);
                setCurrentId(currenIdInfo);
            }
        })
        .catch(error => {
            console.log("This is Error:", error);
            setIsLoading(false);
            setError(t('login_error'));
            setEmail('');
            setPassword('');
        });
    };

    useEffect(() => {
        if (token) {
            localStorage.setItem('userId', token);
            localStorage.setItem('userType', userType);
            localStorage.setItem('currentId', currentId);
            if (userType) {
                window.location.href = "/home";
            } else {
                window.location.href = "/complete-profile";
            }
        }
    }, [token, userType, currentId]);

    return (
        <>
            <div className="sign_in_sec current" style={{color: 'black', background: 'white', border: '1px solid green', backgroundImage: 'url("https://www.transparenttextures.com/patterns/cubes.png")'}} id="tab-1">
                <h3 style={{margin: '10px'}}>{t('sign_in')}</h3>
                <form onSubmit={handleLogin}>
                    <div className="row">
                        <div className="col-lg-12 no-pdd">
                            <div className="sn-field">
                                <input type="email" 
                                    value={email} 
                                    placeholder={t('enter_email')} 
                                    id="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    readOnly={location.state && location.state.email} // Lock the email field if redirected
                                />
                                <i className="la la-user"></i>
                            </div>
                        </div>
                        <div className="col-lg-12 no-pdd">
                            <div className="sn-field">
                                {error && (
                                    <p style={{ color: 'red', textAlign: 'center' }}>
                                        {error}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-12 no-pdd">
                            <div className="sn-field">
                                <input type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    id="psw"
                                    placeholder={t('enter_password')} 
                                    required />  
                                <i className="la la-lock"></i>
                            </div>
                        </div>
                        <div className="col-lg-12 no-pdd">
                            <div className="checky-sec">
                                <div className="fgt-sec">
                                    <input type="checkbox" name="cc" id="c1" />
                                    <label htmlFor="c1">
                                        <span></span>
                                    </label>
                                    <small>{t('remember_me')}</small>
                                </div>
                                <a href="/forgot-password">{t('forgot_password')}</a>  
                            </div>
                        </div>
                        <div className="col-lg-12 no-pdd">
                            {isLoading ? <div className="lds-hourglassMin"></div> :
                                <button type="submit" 
                                    value="submit" 
                                    disabled={isLoading}
                                    style={{borderRadius: '25px'}}
                                    >
                                    <i>{t('sign_in')}</i>
                                </button>
                            }
                        </div>
                    </div>
                </form>
                <hr className="myhr" />
                <div className="login-resources">
                    <h4>{t('login_via_social_account')}</h4>
                    <ul>
                        <GoogleButton />
                        <FacebookButton />
                    </ul>
                </div>
            </div>
        </>
    );
}
