import React, { useEffect, useState } from 'react';
import { Container, Spinner, Alert, Table } from 'react-bootstrap';

const UserCountReport = () => {
  const [totalUsers, setTotalUsers] = useState(null);
  const [userTypeCounts, setUserTypeCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = localStorage.getItem('userId');

  const fetchTotalUsers = async () => {
    try {
      const response = await fetch('https://meanc4.azurewebsites.net/meanc/users/count_all_users/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch total users');
      }
      const data = await response.json();
      setTotalUsers(data.total_users);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchUserTypeCounts = async (userType) => {
    try {
      const response = await fetch(`https://meanc4.azurewebsites.net/meanc/users/count_users_by_type/?user_type=${userType}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch count for ${userType}`);
      }
      const data = await response.json();
      setUserTypeCounts(prevCounts => ({
        ...prevCounts,
        [userType]: data[`total_${userType}_users`]
      }));
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchTotalUsers();
      await fetchUserTypeCounts('player');
      await fetchUserTypeCounts('recruiter');
      await fetchUserTypeCounts('simple');
      setLoading(false);
    };

    fetchData();
  }, [token]);

  if (loading) {
    return (
      <Container className="text-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert variant="danger">Error: {error}</Alert>
      </Container>
    );
  }

  return (
    <Container style={{ marginTop: '100px' }}>
        
        <h2 className="my-4">User Count Report</h2>
        <div className="table-responsive">
                <Table striped bordered hover>
                <thead>
                <tr>
                    <th>User Type</th>
                    <th>Count</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Total Users</td>
                    <td>{totalUsers}</td>
                </tr>
                <tr>
                    <td>Players</td>
                    <td>{userTypeCounts.player}</td>
                </tr>
                <tr>
                    <td>Recruiters</td>
                    <td>{userTypeCounts.recruiter}</td>
                </tr>
                <tr>
                    <td>Simple Users</td>
                    <td>{userTypeCounts.simple}</td>
                </tr>
                </tbody>
            </Table>
        </div>
    </Container>
  );
};

export default UserCountReport;
