import React, { useState, useContext } from 'react';
import { Col, Row, ListGroup, Card } from 'react-bootstrap';
import VideoModal from '../Others/Videomodal';
import { DataContext } from '../../Context/DataProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';

export default function CardProfile({
  category,
  firstname,
  lastname,
  height,
  weight,
  school,
  play_position,
  hometown,
  achievements,
  experience_level,
  sports,
  team_name,
  src
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { categories } = useContext(DataContext);
  const categoryColors = {
    Basketball: "green",
    Soccer: "blue",
    Baseball: "red",
    Football: "orange",
    NFL: 'navy',
    Tennis: "yellow",
    Volleyball: "purple",
    Other: 'navy'
  };

  // Function to get color based on the category name
  const getColorForCategory = (name) => {
    return categoryColors[name] || 'gray'; // default color if not found
  };

  const handleOpenModal = (videoSrc) => {
    // setCurrentVideoSrc(videoSrc);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      
      <VideoModal isOpen={isModalOpen} onClose={handleCloseModal} videoSrc={src} />
      <Card className="user-card my-3">
        <Row className="g-0">
          <Col xs={12} md={8}>
            <Card.Body>
              <Row className="align-items-center">
                <Col xs={12} md={11}>
                  <Card.Title
                    style={{
                      fontWeight: 'bold',
                      fontSize: '1.5rem',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      marginBottom: '20px'
                    }}>
                    {firstname ? firstname : "N/A"} {lastname ? lastname : "N/A"}
                  </Card.Title>
                </Col>
                <Col xs={12} md={12} style={{ marginTop: '-10px' }}>
                  <Card.Title style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                    <span style={{
                      boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)',
                      padding: '5px 9px',
                      backgroundColor: getColorForCategory(category || 'Other'),
                      color: 'white',
                      borderRadius: '25px',
                      fontSize: '14px'
                    }}>
                      {category || 'Other'}
                    </span>
                    <span>
                      <FontAwesomeIcon
                        icon={faPlayCircle}
                        size="2x"
                        color="#2980b9"
                        onClick={handleOpenModal}
                        style={{
                          cursor: 'pointer',
                          display: 'inline-block',
                          marginLeft: '10px',
                        }}
                        className="d-xs-inline d-md-none"
                      />
                    </span>
                  </Card.Title>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <ListGroup variant="flush">
                    <ListGroup.Item className="info-item">
                      <div className="info-label">Height</div>
                      <div className="info-data">{height ? height : 'n/A'}</div>
                    </ListGroup.Item>
                    <ListGroup.Item className="info-item">
                      <div className="info-label">Hometown</div>
                      <div className="info-data">{hometown ? hometown : 'n/A'}</div>
                    </ListGroup.Item>
                    <ListGroup.Item className="info-item">
                      <div className="info-label">School</div>
                      <div className="info-data">{school ? school : 'n/A'}</div>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col xs={6}>
                  <ListGroup variant="flush">
                    <ListGroup.Item className="info-item">
                      <div className="info-label">Club</div>
                      <div className="info-data">{team_name ? team_name : 'n/A'}</div>
                    </ListGroup.Item>
                    <ListGroup.Item className="info-item">
                      <div className="info-label">Sports</div>
                      <div className="info-data">{sports ? sports : 'n/A'}</div>
                    </ListGroup.Item>
                    <ListGroup.Item className="info-item">
                      <div className="info-label">Play position</div>
                      <div className="info-data">{play_position ? play_position : 'n/A'}</div>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Card.Body>
          </Col>
          <Col xs={12} md={4} className="d-none d-md-flex justify-content-center align-items-center" style={{ backgroundColor: '#2980b9', minHeight: '200px' }}>
            <FontAwesomeIcon icon={faPlayCircle} size="4x" color="white" onClick={handleOpenModal} style={{ cursor: 'pointer' }} />
          </Col>
        </Row>
      </Card>
    </>
  );
}
