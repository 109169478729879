import React from 'react';
import { useTranslation } from 'react-i18next';

const UserList = ({ users, conversations, unreadMessages, apiData, searchTerm, handleUserClick, setSearchTerm }) => {
    const filteredUsers = users.filter(user =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.id.toString().includes(searchTerm)
    );
    const { t } = useTranslation();

    const getUserPhoto = (userId) => {
        const user = users.find(user => user.id === userId);
        return user && user.photo ? user.photo : `https://robohash.org/${userId}`;
    };

    const truncateMessage = (message, maxLength = 30) => {
        if (message.length > maxLength) {
            return message.substring(0, maxLength) + '...';
        }
        return message;
    };

    return (
        <div className="col-md-4 friends-list active">
            <h2 style={{ marginTop: '10px' }}>{t('friendsList')}</h2>
            <br />
            <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className="form-control mb-3"
                style={{ boxShadow: '3px 4px 8px rgba(0, 0, 0, 0.1)' }}
            />
            <ul className="list-group" style={{ boxShadow: '3px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                {filteredUsers.map(user => {
                    const conversation = conversations.find(convo => convo.participants.some(participant => participant.id === user.id));
                    const lastMessage = conversation?.messages[conversation.messages.length - 1] || null;
                    const isUnread = unreadMessages.has(user.id);

                    return (
                        user.id !== apiData[0].id && (
                            <li key={user.id} className={`list-group-item ${isUnread ? 'unread' : ''}`} onClick={() => handleUserClick(user.id)} style={{ cursor: 'pointer' }}>
                                <img src={getUserPhoto(user.id)} alt={user.username} style={{ width: 50, height: 50, marginRight: 10, borderRadius: '50%', boxShadow: '0 21px 8px rgba(0, 0, 0, 0.1)' }} />
                                <div>
                                    {user.username} {user.id} - {user.user_type || 'No Provided'}
                                    {lastMessage ? (
                                        <p>Last message: {truncateMessage(lastMessage.message)} (from {lastMessage.sender.username})</p>
                                    ) : (
                                        <p>No conversation yet</p>
                                    )}
                                    {isUnread && <span className="badge bg-primary">New</span>}
                                </div>
                            </li>
                        )
                    );
                })}
            </ul>
        </div>
    );
};

export default UserList;
