// EmailConfirmation.js
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function EmailConfirmation(){ 
  const location = useLocation();
  const confirmationCode = location.pathname.split("/")[2]; // Extract confirmationCode from URL
  console.log("this is the confi",confirmationCode )

  useEffect(() => {
    // Send a GET request to confirm email and retrieve the token
    fetch(`https://meanc4.azurewebsites.net/meanc/confirm-email/${confirmationCode}/`)
      .then((response) => response.json())
      .then((data) => {
        if (data.token) {
          // Email confirmed, store token in localStorage
          localStorage.setItem("userId", data.token);

          // Redirect to the desired page (e.g., home)
          window.location.href = "/home";
        } else {
          // Handle error, display a message, or redirect to an error page
        }
      })
      .catch((error) => {
        // Handle fetch error, display a message, or redirect to an error page
      });
  }, [confirmationCode]);

  return (
    <div>
      <p>Confirming your email... toto</p>
      {/* You can add a loading spinner or message here */}
    </div>
  );
};

