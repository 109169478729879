import { useEffect, useState } from "react";
import React from 'react';
export default function Content(){
    const [data,setData] = useState([])
    //const [checkUpdated, setCheckUpdated] = useState([])
    //const [newData, setNewData] = useState({})
    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    //const [feactCount, setCount] = useState(0)

    useEffect(()=>{
        setIsLoading(true);
        fetch('http://localhost:8000/meanc/posts/')
        .then((res)=>res.json())
        .then(da=> {
            
            setIsLoading(false);
            //setData([...data, newData])
            setData(da)
        })
        .catch(error=>{
            setIsError(true)
            setIsLoading(false)
        })
    },[])
    console.log(data)
    console.log(isLoading)
    /*setInterval(() => {
        fetch('http://localhost:8000/meanc/posts/')
        .then((res)=> res.json())
        .then(data=> {
            console.log(data)
            //setCheckUpdated(data)
            setData(data)
            console.log(checkUpdated)
        })
        .catch(error=>{
            setIsError(true)
            //setIsLoading(false)
            })
    }, 5000); */
    
    //function handler newData
    //const handleAddItem = (item) =>{
      //  setNewData(item)
    //}
    //console.log(feactCount)
    // the return component each by each

    return (
        <div style={{ backgroundColor: "white", color: "black" }}>
            
            <input type="text" id="search-input" placeholder="Search for names.. category, sports.."></input>
            <h3>What wrong</h3>
            {isError && <div>Something went wrong ...</div>}
            {isLoading ? (
                <div>Loading ...</div>
            ) : (
    
                data.forEach(item => (
                <div key={item.id} className="card">
                    <img src={`https://robohash.org/${item.id}`} alt="Avatar" id="avatar"/>
                    <h2>{item.title}</h2>
                    <p>{item.content}</p>
                </div>
                ))
            )}
        </div>
    )



}