import React from 'react';
import './Privacy.css';

const PrivacyPolicy = () => {
  
  return (
    <div className="privacy-policy">
      
      <button onClick={() => window.history.back()} style={{padding: '10px 20px', borderRadius: '10px', background: '#3498db', color: '#fff', border: 'none', fontWeight: 'bold', cursor: 'pointer'}}>Go to Home</button>
      <h1>Privacy Policy</h1>
      <p>Thank you for choosing Meanc, the ultimate global sports platform and social network. We take your privacy and security very seriously, and we want to be transparent about how we collect, use, and protect your personal information. This Privacy Policy explains our practices regarding the information we collect from you when you use our platform, and how we use and share that information.</p>
      
      <h2>1. Collection of Information</h2>
      <p>When you use Meanc, we collect certain information from you such as your name, email address, date of birth, gender, location, and sports interests. We may also collect information about your device, operating system, IP address, and usage data.</p>
      
      <h2>2. Use of Information</h2>
      <p>We use the information we collect from you to provide, maintain, and improve our platform and services, to personalize your experience, to communicate with you, to facilitate transactions, and to comply with legal obligations. We may also use your information for research and analytics purposes.</p>
      
      <h2>3. Sharing of Information</h2>
      <p>We may share your information with third-party service providers who assist us in providing our services, such as payment processors, hosting providers, and customer support providers. We may also share your information with other users of the platform if you choose to make it publicly available. We may disclose your information if required by law or if we believe it is necessary to protect our rights or the rights of others.</p>
      
      <h2>4. Security of Information</h2>
      <p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no security measures are perfect, and we cannot guarantee the security of your information.</p>
      
      <h2>5. Your Choices</h2>
      <p>You can choose not to provide certain information or to delete your account at any time. You can also manage your communication preferences and opt-out of receiving promotional emails.</p>
      
      <h2>6. Changes to this Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. If we make material changes to this Privacy Policy, we will notify you by email or by posting a notice on our platform.</p>
      
      <p>If you have any questions or concerns about this Privacy Policy, please contact us at privacy@meanc.com.</p>
    </div>
  );
};

export default PrivacyPolicy;
