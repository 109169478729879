import React from "react";
import { useTranslation } from 'react-i18next';

export default function SignInfo() {
  const { t } = useTranslation();

  return (
    <>
      <div className="cmp-info">
        <div className="cm-logo">
          <img src="images/cm-logo.png" alt="" />
          <p className="btn" style={{
            color: 'white',
            background: '#2980b9',
            backgroundImage: 'url("https://www.transparenttextures.com/patterns/cubes.png")'
          }}>
            {t('welcome_message')}
          </p>
          <p className="btn" style={{
            color: 'black',
            background: 'white',
            boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.25)',
            marginTop: '5px'
          }}>
            <br />
            {t('join_us_message')}
          </p>
        </div>
        <img src="images/cm-main-img.png" alt="" />
      </div>
    </>
  );
}
