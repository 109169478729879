import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form, Spinner, ProgressBar } from "react-bootstrap";
import '../../Styles/CreateRecordModal.css';
import VideoFileDropZone from "../../Services/VideoFileDropZone";
import { post } from "../../Services/Api"; // Import the post function from your Api.js
import { useTranslation } from 'react-i18next';
import 'react-html5-camera-photo/build/css/index.css';
import { DataContext } from "../../Context/DataProvider";

export default function CreateRecordModal({
  isOpen,
  onClose,
  onCreate,
  updateData, // New prop to update elevesData in ViewRecordModale
  apiUrl, // The API URL for the POST request
  initialFormData, // An object with the initial form data structure
  onCreateSuccess, // New prop for success callback
}) {
  const [formData, setFormData] = useState(initialFormData);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { categories } = useContext(DataContext);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  // Update formData when the component mounts
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "video_file" && files.length > 0) {
      const file = files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
      setFile(files[0]);
    } else {
      // Handle text inputs
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.title) {
      newErrors.title = t('titleRequired');
    }
    // Add other field validations as needed
    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setLoading(true);
    setUploadProgress(0); // Reset progress on new submission

    try {
      const data = await post(apiUrl, formData, (progress) => {
        setUploadProgress(progress); // Update state with progress
      });

      console.log('Data sent successfully:', data);
      // Handle success (e.g., clear form, hide modal, call success callback)
      setLoading(false);
      onClose();
      if (onCreateSuccess) {
        onCreateSuccess();
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal 
      show={isOpen} 
      onHide={onClose} 
      size="lg" 
      style={window.innerWidth <= 768 ? { marginTop: '200px' } : {}}
    >
      <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title>
          <span style={{ padding: '7px 12px', borderRadius: '25px' }}>
            {t('addVideo')}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: '#f4f4f4 !important' }}>
        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
          <div style={{ height: "100%", overflowY: "auto", padding: "10px" }}>
            <Form onSubmit={handleSubmit} style={{paddingRight:'10px', paddingLeft:'10px'}}>
              {Object.keys(initialFormData).map((field) => (
                <Form.Group key={field}>
                  {(field === "uploaded_by" || field === "title" || field === "description" || field === 'category' || field === 'video_file') ? (
                    <Form.Label style={{ display: 'none' }}>
                      {t(field)}
                    </Form.Label>
                  ) : (
                    <Form.Label>{t(field)}:</Form.Label>
                  )}

                  {field === "category" ? (
                    <Form.Control
                      as="select"
                      name={field}
                      value={formData[field]}
                      onChange={handleChange}
                      className="custom-form-control"
                      isInvalid={!!errors[field]}
                    >
                      <option value="">{t('selectCategory')}</option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>{category.name}</option>
                      ))}
                    </Form.Control>
                  ) : field === "video_file" ? (
                    <Form.Group key={field}>
                      <VideoFileDropZone setFormData={setFormData} setFile={setFile} />
                    </Form.Group>
                  ) : field === "date_of_birth" ? (
                    <Form.Group key={field}>
                      <Form.Control
                        type="date"
                        name={field}
                        value={formData[field]}
                        onChange={handleChange} // Add a separate handler for file input
                        className="custom-form-control"
                      />
                    </Form.Group>
                  ) : field === "uploaded_by" ? (
                    <Form.Group key={field}>
                      <Form.Control
                        type="text"
                        name={field}
                        value={formData[field]}
                        onChange={handleChange}
                        className="custom-form-control"
                        style={{ display: 'none' }} // Inline style to hide the input
                      />
                    </Form.Group>
                  ) : field === "title" ? (
                    <Form.Group key={field}>
                      <Form.Control
                        type="text"
                        name={field}
                        value={formData[field]}
                        onChange={handleChange}
                        className="custom-form-control"
                        placeholder={t('title')}
                        isInvalid={!!errors.title}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : field === "description" ? (
                    <Form.Group key={field}>
                      <Form.Control
                        type="text"
                        name={field}
                        value={formData[field]}
                        onChange={handleChange}
                        className="custom-form-control"
                        placeholder={t('description')}
                      />
                    </Form.Group>
                  ) : (
                    <Form.Control
                      type="text"
                      name={field}
                      value={formData[field]}
                      onChange={handleChange}
                      className="custom-form-control"
                    />
                  )}
                </Form.Group>
              ))}
              <div className="d-flex justify-content-between">
                <Button 
                  variant="danger" 
                  onClick={onClose} 
                  className="btn btn-danger !important"
                  style={{ borderRadius: '25px' }}
                >
                  {t('cancel')}
                </Button>
                <Button 
                  variant="primary" 
                  type="submit" 
                  className="btn btn-primary !important"
                  style={{ borderRadius: '25px' }}
                  disabled={loading}
                >
                  {loading ? <Spinner animation="border" size="sm" /> : t('send')}
                </Button>
              </div>
              <br />
              {loading && (
                <ProgressBar
                  now={uploadProgress}
                  label={`${uploadProgress}%`}
                  striped
                  animated
                />
              )}
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
