import React from "react";

export default function ({category}){
    return (
        <>
            <div className="epi-sec">
                    <ul className="descp">
                        <li><img src="images/icon8.png" alt="" /><span style={{color:'black'}}>{category}</span></li>
                        <li><img src="images/icon9.png" alt="" /><span style={{color:'black'}}>USA</span></li>
                        <li><img src="images/icon9.png" alt="" /><span style={{color:'black'}}>Colombus</span></li>
                    </ul>
                    {/*{canDelete(item) && (
                            <ul className="bk-links">
                            <li><a href="#" title=""><i className="la la-bookmark"></i></a></li>
                            <li onClick={()=>handleDeletePost(item.id)} style={{cursor:'pointer'}}><i className="las la-trash"></i></li>
                            </ul>
                    )}*/}
                </div>
        </>
    )
}