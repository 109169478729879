import React, { useState, useContext } from "react";
import CreateRecordModal from "../Modal/CreateRecordModal";
import ApiContext from "../globalStore/ApiContext";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

export default function HomeRightSide1() {
  const { apiData } = useContext(ApiContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleCreate = (newRecord) => {
    // Update the data state with the new record
    setData([...data, newRecord]);
  };

  return (
    <>
      <div className="widget widget-about">
        <img src="images/logo1.png" alt="" style={{
          width: '200px',
          height: '120px',
          padding: '14px',
        }} />
        <img src="images/bg3.jpg" alt="" style={{
          width: '200px',
          height: '200px',
          padding: '14px',
          marginTop: '-29px',
          marginBottom: '-60px'
        }} />
        <h3 style={{ padding: '34px', marginBottom:'-5px' }}>{t('unlockYourAthleticPotential')}</h3>
        <div className="sign_link">
          {apiData[0]?.user_type==='player'? (
            <>
              <h3 
                style=
                {{
                  marginBottom:'10px',
                  color: '#fff',
                  fontSize: '16px',
                  padding: '10px 55px',
                  display: 'inline-block',
                  backgroundColor:'#2980b9',
                  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                  borderRadius: '25px',
                  cursor: 'pointer',
                }} 
                onClick={openModal}>
              {t('addVideo')}
              </h3><br />
              <Link to="/About" title="">{t('learnMore')}</Link>
            </>
          ):(
            <>
            <Link to="/About" title="">{t('learnMore')}</Link>
            </>
          )}
        </div>
        <CreateRecordModal
          isOpen={modalIsOpen}
          onClose={closeModal}
          onCreate={handleCreate}
          apiUrl="/meanc/video/"
          initialFormData={{
            title:'',
            category: '',
            video_file: '',
            uploaded_by: apiData[0]?.id
          }}
        />
      </div>
    </>
  )
}
