import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";

const GoogleButton = () => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState("");
  const [error, setError] = useState(false);

  function handleCallbackResponse(response) {
    console.log("Encoded JWT ID token: " + response.credential);
    var userObject = jwt_decode(response.credential);
    console.log(userObject);
    setUser(userObject);

    // Send the token to the backend
    fetch("https://meanc4.azurewebsites.net/meanc/logingoogle/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: response.credential }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.token);
        console.log("Is the data from the profile_completion: ",data.profile_completed)
        if (!data.token) {
          setError(true);
        } else {
          setToken(data.token);
          localStorage.setItem("userId", data.token);
          localStorage.setItem("user_id",data.user_id);
          if (data.user_type) {
            window.location.href = "/home";
          } else {
            window.location.href = "/complete-profile";
          }
        }
      })
      .catch((error) => {
        console.log("This is Error:", error);
        setError(true);
      });
  }

  useEffect(() => {
    /* global google */
    if (typeof google !== "undefined" && google.accounts && google.accounts.id) {
      google.accounts.id.initialize({
        client_id: "337528326009-b3bt1sk5vsgfqpqbvv7r46ua0r1k0hfb.apps.googleusercontent.com",
        callback: handleCallbackResponse,
      });
      google.accounts.id.renderButton(document.getElementById("signInDiv"), {
        theme: "filled_blue",
        size: "large",
        shape: "pill",
        width: 250,
      });
    }
  }, []);

  return <div id="signInDiv"></div>;
};

export default GoogleButton;
