import React, { useState } from "react";
import { Navbar, Container, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Footer from "../PageLayout/Footer";

export default function SignUpBuffer() {
    const [confirmationCode, setConfirmationCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();

        // Send a GET request to confirm email and retrieve the token
        fetch(`https://meanc4.azurewebsites.net/meanc/confirm-email/${confirmationCode}/`)
            .then((response) => response.json())
            .then((data) => {
                if (data.token) {
                    // Email confirmed, store token in localStorage and the user_id
                    localStorage.setItem("userId", data.token);
                    localStorage.setItem("user_id",data.user_id);

                    // Redirect to the desired page (e.g., home)
                    if (data.user_type) {
                        window.location.href = "/home";
                    } else {
                        window.location.href = "/complete-profile";
                    }
                } else {
                    // Handle error, set an error message
                    setErrorMessage("Invalid or expired confirmation code. Please try again.");
                }
            })
            .catch((error) => {
                // Handle fetch error, set an error message
                setErrorMessage("An error occurred while confirming your email. Please try again.");
            });
    };

    return (
        <>
            <Navbar expand="lg" bg="light" className=" custom-navbar align-items-left fixed-top"
                style={{ boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)' }}>
                <Container>
                    <Navbar.Brand style={{ marginLeft: '10px' }}>
                        <Link to="#">
                            <Image
                                src="images/logo12.png"
                                alt="My Logo"
                                style={{ width: '70px', height: '40px' }}
                            />
                        </Link>
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <div className="confirmation-container">
                <div className="image-container">
                    <img src="/images/confirmation.png" alt="Buffer Zone" className="illustration" />
                </div>
                <div className="form-container">
                    <h1>Please check your email and enter the confirmation code you received:</h1>
                    {/*<p>Waiting for your confirmation code email...</p>*/}
                    
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            className="form-control"
                            value={confirmationCode}
                            onChange={(e) => setConfirmationCode(e.target.value)}
                            placeholder="Confirmation Code"
                            required
                        />
                        <button type="submit" className="btn-primary">Confirm</button>
                    </form>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                </div>
            </div>
            <Footer />
        </>
    );
}

