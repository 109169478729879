import React from "react";
import Footer from "../Components/PageLayout/Footer";
import Header2 from "../Components/PageLayout/Header2";
import Profiles from "../Components/Recruiters/Profiles";

export default function Recruiter(){
    return (
        <>
        <Header2 />
            <Profiles />
        <Footer />
        </>
    )
}