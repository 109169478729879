import React from "react";
import { useNavigate } from "react-router-dom";
import CountryName from "../../Utils/CountryName";
import TimeComponent from "../../Utils/TimeComponent";
import moment from "moment";

export default function TopPost({ user_id, created, username, photo, country }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/profiles/${user_id}/`);
  };

  return (
    <div className="post_topbar">
      <div className="usy-dt">
        {photo ? (
          <img
            src={`${photo}`}
            alt="Avatar"
            className="avatar"
            style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor: 'pointer' }}
            onClick={handleClick}
          />
        ) : (
          <img
            src={`https://robohash.org/${user_id}`}
            alt="Avatar"
            className="avatar"
            style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor: 'pointer' }}
            onClick={handleClick}
          />
        )}
        <div className="usy-name">
          <h3 style={{ cursor: 'pointer' }} onClick={handleClick}>{username}</h3>
          <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src="images/clock.png" alt="" style={{ marginRight: '-5px' }} />
            <span><TimeComponent time={created} /></span>
            <ul className="descp" style={{ listStyle: 'none',paddingLeft: '10px', margin: '0', display: 'flex', alignItems: 'center' }}>
                <li style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="images/icon9.png" alt="" style={{ marginRight: '5px' }} />
                    <span style={{ color: 'black' }}>{country? country:'No provided'}</span> 
                    {/*(<CountryName countryCode={country} />)*/}
                </li>
            </ul>
        </span>

        </div>
      </div>
      
    </div>
  );
}
