import React from 'react';
import { Row, Col, ListGroup, Form, Button } from 'react-bootstrap';

export default function RecruiterProfile({ extraData,user,handleEditClick, isEditing, formData, handleInputChange, handleSaveClick }) {
    return (
        <div className="col-lg-6">
            <div className="main-ws-sec">
                <div className="user-tab-sec">
                    <h3>{user?.first_name || "N/A"} {user?.last_name || "N/A"}</h3>
                    <div className="star-descp">
                        <span>Recruiter</span>
                        <button
                            style={{
                                float: 'right',
                                background: '#2980b9',
                                border: 'none',
                                boxShadow: '0px 24px #fff',
                                padding: '10px',
                                color: '#fff',
                            }}
                            onClick={isEditing ? handleSaveClick : handleEditClick}  // Toggle between edit and save
                        >
                            {isEditing ? 'Cancel' : 'Edit'}
                        </button>
                    </div>
                    <div className="tab-feed">
                        <Row>
                            <Col xs={6}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item className="info-item">
                                        <div className="info-label">Company</div>
                                        <div className="info-data">
                                            {formData.company? formData.company:<div className="loader"></div>}
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="info-item">
                                        <div className="info-label">Contact Number</div>
                                        <div className="info-data">
                                            {formData.contact_number? formData.contact_number:<div className="loader"></div>}
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
}
