import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import ApiContext from "../globalStore/ApiContext";

const ProfileEditModal = ({ show, handleClose,formData,setFormData, user, handleSaveClick, userType }) => {
    // const { setApiData } = useContext(ApiContext);
    // const [formData, setFormData] = useState({
    //     height: '',
    //     weight: '',
    //     play_position: '',
    //     team_name: '',
    //     school: '',
    //     company: '',
    //     contact_number: '',
    //     hobbies: '',
    //     interests: '',
    //     occupation: ''
    // });

    useEffect(() => {
        if (user) {
            setFormData({
                height: formData.height || '',
                weight: formData.weight || '',
                play_position: formData.play_position || '',
                team_name: formData.team_name || '',
                school: formData.school || '',
                company: formData.company || '',
                contact_number: formData.contact_number || '',
                hobbies: formData.hobbies || '',
                interests: formData.interests || '',
                occupation: formData.occupation || ''
            });
        }
    }, [user]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const renderPlayerFields = () => (
        <>
            <Form.Group controlId="formHeight">
                <Form.Label>Height</Form.Label>
                <Form.Control
                    type="text"
                    name="height"
                    value={formData.height}
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Form.Group controlId="formWeight">
                <Form.Label>Weight</Form.Label>
                <Form.Control
                    type="text"
                    name="weight"
                    value={formData.weight}
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Form.Group controlId="formPlayPosition">
                <Form.Label>Play Position</Form.Label>
                <Form.Control
                    type="text"
                    name="play_position"
                    value={formData.play_position}
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Form.Group controlId="formTeamName">
                <Form.Label>Team Name</Form.Label>
                <Form.Control
                    type="text"
                    name="team_name"
                    value={formData.team_name}
                    onChange={handleInputChange}
                />
            </Form.Group>
        </>
    );

    const renderRecruiterFields = () => (
        <>
            <Form.Group controlId="formCompany">
                <Form.Label>Company</Form.Label>
                <Form.Control
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Form.Group controlId="formContactNumber">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                    type="text"
                    name="contact_number"
                    value={formData.contact_number}
                    onChange={handleInputChange}
                />
            </Form.Group>
        </>
    );

    const renderSimpleUserFields = () => (
        <>
            <Form.Group controlId="formHobbies">
                <Form.Label>Hobbies</Form.Label>
                <Form.Control
                    type="text"
                    name="hobbies"
                    value={formData.hobbies}
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Form.Group controlId="formInterests">
                <Form.Label>Interests</Form.Label>
                <Form.Control
                    type="text"
                    name="interests"
                    value={formData.interests}
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Form.Group controlId="formOccupation">
                <Form.Label>Occupation</Form.Label>
                <Form.Control
                    type="text"
                    name="occupation"
                    value={formData.occupation}
                    onChange={handleInputChange}
                />
            </Form.Group>
        </>
    );

    return (
        <Modal show={show} onHide={handleClose} style={{ marginTop: '160px' }}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* Conditionally render fields based on user_type */}
                    {userType === 'player' && renderPlayerFields()}
                    {userType === 'recruiter' && renderRecruiterFields()}
                    {userType === 'simple' && renderSimpleUserFields()}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSaveClick}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProfileEditModal;
