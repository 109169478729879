import React, { useState } from 'react';
import { Navbar, Container, Nav, Image, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Header4() {
  const {t, i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setDropdownOpen(false); // Close dropdown on language change
  };

  return (
    <>
      <Navbar expand="lg" bg="light" className="custom-navbar align-items-left fixed-top"
        style={{ boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <Container>
          <Navbar.Brand style={{ marginLeft: '10px' }}>
            <Link to="/">
              <Image
                src="https://meanc.net/images/logo12.png"
                alt="My Logo"
                style={{ width: '70px', height: '40px' }}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" className="custom-toggler" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0">
            </Nav>
            <Nav className="ml-auto">
              {/* This Nav is for alignment */}
            </Nav>
          </Navbar.Collapse>
          {/* Dropdown positioned separately */}
          <Dropdown 
            className="language-dropdown" 
            onToggle={(isOpen) => setDropdownOpen(isOpen)}
            show={dropdownOpen}
          >
            <Dropdown.Toggle variant="light" id="dropdown-basic">
              <FontAwesomeIcon icon={faGlobe} /> language ({i18n.language})
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => changeLanguage('en')}>English</Dropdown.Item>
              <Dropdown.Item onClick={() => changeLanguage('fr')}>Français</Dropdown.Item>
              <Dropdown.Item onClick={() => changeLanguage('es')}>Español</Dropdown.Item>
              <Dropdown.Item onClick={() => changeLanguage('ar')}>العربية</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Container>
      </Navbar>
      {dropdownOpen && <div className="overlay" onClick={() => setDropdownOpen(false)}></div>}
    </>
  );
}
