import React,{useContext, useEffect} from 'react';
import { Navbar, Container, Nav, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments,faHome, faPerson, faMedal,faPersonChalkboard, faSignOut, faHandPointRight,faMessage} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import ApiContext from '../globalStore/ApiContext';

export default function Header3({ onLogout }) {
  const { apiData } = useContext(ApiContext);
  const token = localStorage.getItem('userId');
  console.log("this is APidata", apiData);

  const handleLogout = (e) => {
      e.preventDefault();
      console.log("Here a click")
      // Clear localStorage on logout
      localStorage.removeItem('userId'); // Remove the token key as well
      window.location.href = '/';
      // Implement your logout logic here
      console.log('Logout clicked'); 
    };
    useEffect(()=>{
          if (!token){
          window.location.href = '/';
          }
    },[]);
  return (
    <Navbar expand="lg" bg="light" className=" custom-navbar align-items-left fixed-top"
    style={{ boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
       }}
     >
      <Container>
        <Navbar.Brand style={{ marginLeft: '10px' }}>
          <Link to="/complete-profile">
            <Image
              src="https://meanc.net/images/logo12.png"
              alt="My Logo"
              style={{ width: '70px', height: '40px' }}
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" className="custom-toggler" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0">
            </Nav>
            <Nav className="ml-auto">
              <Link to="/complete-profile" className="nav-link white-text">
                <FontAwesomeIcon icon={faPersonChalkboard} /> Hi, {apiData[0]?.username || ''}
              </Link>
              <Link onClick={handleLogout} className="nav-link white-text logout-link">
                <FontAwesomeIcon icon={faSignOut}  /> Logout
              </Link>
            </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
