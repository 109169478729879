import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Logout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // Clear localStorage on logout
    localStorage.removeItem('userId');
    localStorage.removeItem('currentId');
    localStorage.removeItem('user_id');
    localStorage.removeItem('userType');

    // Redirect to login or home page after logout
    navigate('/');
  }, [navigate]);

  return (
    <div>
      {t('loggingOut')}
    </div>
  );
};

export default Logout;
