import React from "react";
import Header2 from "../PageLayout/Header2";
import Footer from "../PageLayout/Footer";
import ReferralLinkGenerator from "./ReferralLinkGenerator";

export default function HomeReferal(){

    return(
        <>
        <Header2 />
            <ReferralLinkGenerator />
        <Footer />
        </>
    )
}