import React, { useState, useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import VideoModal from "../Others/Videomodal";
import { SearchContext } from "../globalStore/SearchContext";
import { useTranslation } from 'react-i18next';
import { DataContext } from "../../Context/DataProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPerson, faMedal, faVideoCamera, faUserAlt, faUserFriends, faBaseball, faBasketball } from '@fortawesome/free-solid-svg-icons';
import FollowButton from "../../Utils/FollowButton";
import CountryFlag from "../../Utils/CountryFlag";

export default function TalentVideoDetail({ data }) {
  const { searchItem } = useContext(SearchContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoSrc, setCurrentVideoSrc] = useState("");
  const postRefs = useRef(new Map()); // Using Map to store multiple refs
  const navigate = useNavigate(); // For navigation
  const { t } = useTranslation();

  console.log("data from Talents:", data);
  console.log("searchItem from context:", searchItem);

  const { categories } = useContext(DataContext);
  const categoryColors = {
    Basketball: "green",
    Soccer: "blue",
    Baseball: "red",
    Football: "orange",
    NFL: 'navy',
    Tennis: "yellow",
    Volleyball: "purple",
    Other: 'navy'
  };

  // Function to get color based on the category name
  const getColorForCategory = (name) => {
    return categoryColors[name] || 'gray'; // default color if not found
  };

  const openModal = (videoSrc, id) => {
    setCurrentVideoSrc(videoSrc);
    setIsModalOpen(true);
    // Store the reference of the post element that opened the modal
    const postElement = postRefs.current.get(id);
    if (postElement) {
      postElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentVideoSrc("");
  };

  const highlightSearchTerm = (text, searchTerm) => {
    if (!searchTerm || typeof searchTerm !== 'string') return text;
    return text.split(new RegExp(`(${searchTerm})`, 'gi')).map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <span key={index} style={{ color: 'red' }}>{part}</span>
      ) : part
    );
  };

  const handleProfileClick = (user_id) => {
    navigate(`/profiles/${user_id}/`);
  };

  if (!data) {
    return (
      <div className="process-comm">
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="wrapper">
      {searchItem && (
        <p style={{ color: 'red', fontWeight: 'bold', fontSize: '20px', textAlign:'center' }}>{data.length} result(s) found</p>
      )}
      <div className="row">
        {data.map(item => (
          <div key={item.id} className="col-lg-3 col-md-4 col-sm-6 col-12"
               ref={el => postRefs.current.set(item.id, el)}>
            <div className="company_profile_info">
              <div className="company-up-info">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div  style={{marginLeft:'95px'}}>
                    {item.photo ? (
                      <img 
                        src={`${item.photo}`} 
                        alt="Avatar" 
                        style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor: 'pointer' }} 
                        onClick={() => handleProfileClick(item.id)} // Add click handler
                      />
                    ) : (
                      <img 
                        src={`https://robohash.org/${item.id}`} 
                        alt="Avatar" 
                        style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor: 'pointer' }} 
                        onClick={() => handleProfileClick(item.id)} // Add click handler
                      />
                    )}
                  </div>
                  <CountryFlag countryCode={item.country}/>
                </div>
                <h4 
                  style={{ cursor: 'pointer' }} 
                  onClick={() => handleProfileClick(item.id)} // Add click handler
                >
                  {item.username} <FontAwesomeIcon icon={faPerson} />
                </h4> 
                <h4><FontAwesomeIcon icon={faUserFriends} /> ({highlightSearchTerm(item.followers_count, searchItem)})</h4>
                <h4 style={{
                      boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)',
                      padding: '5px 9px',
                      backgroundColor: getColorForCategory(item.video_category_name || 'Other'),
                      color: 'white',
                      borderRadius: '25px',
                      fontSize: '14px',
                      marginLeft:'50px',
                      marginRight:'50px'
                    }}>
                  <FontAwesomeIcon icon={faBasketball} /> {item.video_category_name ? item.video_category_name : t('not_provided')}
                </h4>
                <ul>
                  <li>
                    <FollowButton userId={item.id} /> {/* Use FollowButton component */}
                  </li>
                </ul>
              </div>
              <a 
                onClick={() => handleProfileClick(item.id)}
                 style={{ margin: "10px 34px", float: 'left', color:'#2980b9',cursor:'pointer' , boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", padding:'10px' , borderRadius:'50%' }}>
                <FontAwesomeIcon icon={faUserAlt} />
              </a>
              <a href="#"
                onClick={(event) => {
                    event.preventDefault();
                    openModal(item.processed_video_url, item.id);
                }}
                style={{ margin: "10px 34px", float: 'right', color:'#2980b9', boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", padding:'10px' , borderRadius:'50%' }}>
                <FontAwesomeIcon icon={faVideoCamera} />
              </a>

            </div>
          </div>
        ))}
      </div>
      <VideoModal
        isOpen={isModalOpen}
        onClose={closeModal}
        videoSrc={currentVideoSrc}
      />
    </div>
  );
}
