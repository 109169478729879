import React, { useContext, useState } from "react";
import Modal from "react-modal";
import ApiContext from "../globalStore/ApiContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faCalendarAlt, faEnvelope, faEdit, faLock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from 'react-i18next';

Modal.setAppElement("#root"); // Set the root element for accessibility

export default function HomeUserProfile() {
  const token = localStorage.getItem("userId"); // Ensure correct token retrieval
  const { apiData, setApiData } = useContext(ApiContext);
  const { t, i18n } = useTranslation();
  console.log("this is my token", token)

  const [showMore, setShowMore] = useState(false);
  const [editingFirstName, setEditingFirstName] = useState(false);
  const [editingLastName, setEditingLastName] = useState(false);
  const [editingUserType, setEditingUserType] = useState(false);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [firstName, setFirstName] = useState(apiData[0]?.first_name || "n/A");
  const [lastName, setLastName] = useState(apiData[0]?.last_name || "n/A");
  const [userType, setUserType] = useState(apiData[0]?.user_type || "n/A");
  const [photo, setPhoto] = useState(apiData[0]?.photo || "");
  const [photoFile, setPhotoFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate(`/profile`, { state: { user: apiData[0] } });
  };

  const handleSaveChanges = async () => {
    setIsLoading(true); // Start loading
    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("user_type", userType);
    if (photoFile) {
      formData.append("photo", photoFile);
    }

    console.log("Updated Data:", firstName, lastName, userType, photoFile); // Debugging log

    try {
      const response = await fetch(`https://meanc4.azurewebsites.net/meanc/users/${apiData[0].id}/`, {
        method: 'PUT',
        headers: {
          'Authorization': `Token ${token}`
        },
        body: formData
      });

      console.log("Response Status:", response.status); // Debugging log

      if (response.ok) {
        const updatedUser = await response.json();
        setApiData([updatedUser]);
        //alert("Profile updated successfully!");
      } else {
        const errorData = await response.json();
        console.error("Failed to update profile:", errorData); // Debugging log
        alert(t("failedToUpdateProfile"));
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      alert(t("errorUpdatingProfile"));
    }

    setEditingFirstName(false);
    setEditingLastName(false);
    setEditingUserType(false);
    setShowPhotoModal(false);
    setIsLoading(false); // Stop loading
  };

  const isArabic = i18n.language === 'ar';

  return (
    <>
      {!apiData || apiData.length === 0 ? (
        <div className="lds-facebook">
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <div className="user-data full-width">
          <div className="user-profile">
            <div className="username-dt">
              <div className="usr-pic" style={{ position: 'relative' }}>
                {photo ? (
                  <img src={photo} alt={`User ${apiData[0].id}`} />
                ) : (
                  <img src={`https://robohash.org/${apiData[0].id}`} alt={`User ${apiData[0].id}`} />
                )}
                <FontAwesomeIcon 
                  icon={faEdit} 
                  style={{ cursor: 'pointer',fontSize:'25px', position: 'absolute', color:'white', top: '-20px', left: '10px' }} 
                  onClick={() => { console.log("Icon clicked"); setShowPhotoModal(true); }} 
                />
              </div>
            </div>
            <div className="user-specs">
              <br />
              <h3>{apiData[0].username} {apiData[0].id}</h3>  
              <FontAwesomeIcon icon={faEnvelope} /> <span>{apiData[0].email}</span><br/><br />
              <FontAwesomeIcon icon={faCalendarAlt} /> <span>{t('memberSince')} {moment(apiData[0].date_joined).fromNow()}</span>
            </div>
          </div>
          <div className={`show-more ${showMore ? 'active' : ''}`} onClick={() => setShowMore(!showMore)} style={{ cursor: 'pointer' }}>
            {showMore ? t('showLess') : t('showMore')}
          </div>
          <ul className="user-fw-status">
            <li style={{ textAlign: 'center' }}>
              {editingFirstName ? (
                <span>
                  <input 
                    type="text" 
                    value={firstName} 
                    onChange={(e) => setFirstName(e.target.value)} 
                    placeholder={t('firstName')}
                  />
                  {isLoading ? (
                    <div className="loader"></div>
                  ) : (
                    <button onClick={handleSaveChanges} className="save-user-status" style={{ cursor: 'pointer'}}>{t('save')}</button>
                  )}
                </span>
              ) : (
                <span style={{fontSize:'20px', display: 'flex', justifyContent: 'center'}}>
                  {isArabic ? (
                    <>
                      {firstName ? firstName : 'N/a'}
                      <FontAwesomeIcon 
                        icon={faEdit} 
                        style={{ cursor: 'pointer', marginLeft: '10px' }} 
                        onClick={() => setEditingFirstName(true)} 
                      />
                      <strong style={{marginRight: '5px', color:'gray'}}>: {t('firstName')}</strong>
                    </>
                  ) : (
                    <>
                      <strong style={{marginRight: '5px', color:'gray'}}>{t('firstName')}:</strong> {firstName ? firstName : 'N/a'} 
                      <FontAwesomeIcon 
                        icon={faEdit} 
                        style={{ cursor: 'pointer', marginLeft: '10px' }} 
                        onClick={() => setEditingFirstName(true)} 
                      />
                    </>
                  )}
                </span>
              )}
            </li>
            <li style={{ textAlign: 'center' }}>
              {editingLastName ? (
                <span>
                  <input 
                    type="text" 
                    value={lastName} 
                    onChange={(e) => setLastName(e.target.value)} 
                    placeholder={t('lastName')}
                  />
                  {isLoading ? (
                    <div className="loader"></div>
                  ) : (
                    <button onClick={handleSaveChanges} style={{ cursor: 'pointer' }}>{t('save')}</button>
                  )}
                </span>
              ) : (
                <span style={{fontSize:'20px', display: 'flex', justifyContent: 'center'}}>
                  {isArabic ? (
                    <>
                      {lastName ? lastName : "N/a"}
                      <FontAwesomeIcon 
                        icon={faEdit} 
                        style={{ cursor: 'pointer', marginLeft: '10px' }} 
                        onClick={() => setEditingLastName(true)} 
                      />
                      <strong style={{marginRight: '5px', color:'gray'}}>: {t('lastName')}</strong>
                    </>
                  ) : (
                    <>
                    <span style={{fontSize:'20px', display: 'flex', justifyContent: 'center'}}>
                    <strong style={{ marginRight: '5px', color:'gray'}}>{t('lastName')}:</strong> {lastName ? lastName : "N/a"} 
                      <FontAwesomeIcon 
                        icon={faEdit} 
                        style={{ cursor: 'pointer', marginLeft: '10px' }} 
                        onClick={() => setEditingLastName(true)} 
                      />
                    </span>
                     
                    </>
                  )}
                </span>
              )}
            </li>
            <li style={{ textAlign: 'center' }}>
                <span style={{fontSize:'20px', display: 'flex', justifyContent: 'center'}}>
                  {isArabic ? (
                    <>
                      {userType}
                      <FontAwesomeIcon 
                        icon={faLock} 
                        style={{marginLeft: '10px' }} 
                      />
                      <strong style={{marginRight: '5px', color:'gray'}}>: {t('account')}</strong>
                    </>
                  ) : (
                    <>
                      <span style={{fontSize:'20px', display: 'flex', justifyContent: 'center'}}>
                        <strong style={{marginRight: '5px', color:'gray'}}>{t('account')}:</strong>
                        {userType}
                        <FontAwesomeIcon 
                          icon={faLock} 
                          style={{marginLeft: '10px' }} 
                        />
                      </span>
                    </>
                  )}
                </span>
            </li>
            <li>
              <h4><FontAwesomeIcon icon={faUsers} /></h4>
              <span>{apiData[0].follower_count || '0'} {t('followers')}</span>
            </li>
            <li>
            <a className="post_project" 
                style={{color: 'white', cursor: 'pointer', textDecoration: 'none'}} 
                onClick={handleProfileClick} 
                onMouseOver={(e) => {
                    e.currentTarget.style.color = 'black';
                    e.currentTarget.style.textDecoration = 'underline';
                }} 
                onMouseOut={(e) => {
                    e.currentTarget.style.color = 'white';
                    e.currentTarget.style.textDecoration = 'none';
                }}>
                {t('updateProfile')}
            </a>
            </li>
          </ul>
        </div>
      )}

      <Modal
        isOpen={showPhotoModal}
        onRequestClose={() => setShowPhotoModal(false)}
        contentLabel={t("updatePhoto")}
        className="small-modal" // Add this class to style the modal
      >
        <div className="modal-content small-modal-content">
          <span className="close" onClick={() => setShowPhotoModal(false)}>&times;</span>
          <h2>{t("updatePhoto")}</h2>
          <input 
            type="file" 
            onChange={(e) => setPhotoFile(e.target.files[0])} 
            accept="image/*"
            style={{ cursor: 'pointer' }}
          />
          {isLoading ? (
            <div className="loader"></div>
          ) : (
            <button onClick={handleSaveChanges} 
              style={{ 
                cursor: 'pointer', 
                backgroundColor:'#4CAF50', 
                border:'none',
                color:'white',
                padding: '10px 20px',
              }}>{t("save")}</button>
          )}
        </div>
      </Modal>
    </>
  );
}
