import React, { useContext, useState } from 'react';
import { Navbar, Container, Nav, Image, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faHome, faPerson, faMedal, faGlobe, faPersonChalkboard, faHandPointRight, faMessage, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import ApiContext from '../globalStore/ApiContext';
import { useTranslation } from 'react-i18next';

export default function Header2() {
  const { apiData } = useContext(ApiContext);
  const { t, i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const isArabic = i18n.language === 'ar';

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setDropdownOpen(false); // Close dropdown on language change
  };

  return (
    <>
      <Navbar expand="lg" bg="light" className="custom-navbar align-items-left fixed-top" style={{ boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)' }}>
        <Container>
          <Navbar.Brand style={{ marginLeft: '10px' }}>
            <Link to="/home">
              <Image
                src="https://meanc.net/images/logo12.png"
                alt="My Logo"
                style={{ width: '70px', height: '40px' }}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" className="custom-toggler" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0">
              <Link to="/home" className='nav-link white-text'>
                <FontAwesomeIcon icon={faHome} /> {t('home')}
              </Link>
              <Link to="/talents" className="nav-link white-text">
                <FontAwesomeIcon icon={faMedal} /> {t('talents')}
              </Link>
              <Link to="/recruiters" className="nav-link white-text">
                <FontAwesomeIcon icon={faPerson} /> {t('recruiters')}
              </Link>
              <Link to="/messenger" className="nav-link white-text">
                <FontAwesomeIcon icon={faMessage} /> {t('messenger')}
              </Link>
              <Link to="/conversations" className="nav-link white-text">
                <FontAwesomeIcon icon={faComments} /> {t('sportsConversation')}
              </Link>
              <Link to="/referrals" className="nav-link white-text" style={{ background: 'red', borderRadius: '25px', paddingLeft: '15px', boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)' }}>
                <FontAwesomeIcon icon={faHandPointRight} /> {t('referrals')}
              </Link>
            </Nav>
            <Nav className="ml-auto">
              <Link to="/home" className="nav-link white-text">
                <FontAwesomeIcon icon={faPersonChalkboard} /> {isArabic ? `${apiData[0]?.username || ''} ${t('hi_user')}` : `${t('hi_user')} ${apiData[0]?.username || ''}`}
              </Link>

              <Link to="/logout" className="nav-link white-text logout-link">
                <FontAwesomeIcon icon={faSignOut} /> {t('logout')}
              </Link>
              <Dropdown 
                onToggle={(isOpen) => setDropdownOpen(isOpen)}
                show={dropdownOpen}
              >
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  <FontAwesomeIcon icon={faGlobe} /> {t('language')}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => changeLanguage('en')}>English</Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage('es')}>Español</Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage('fr')}>Français</Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage('ar')}>العربية</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {dropdownOpen && <div className="overlay" onClick={() => setDropdownOpen(false)}></div>}
    </>
  );
}
