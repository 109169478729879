import React, { useState } from 'react';

const TruncateMessage = ({ message, maxLength = 200 }) => {
    const [isTruncated, setIsTruncated] = useState(true);

    const toggleTruncate = () => {
        setIsTruncated(!isTruncated);
    };

    const displayedMessage = isTruncated
        ? message.length > maxLength
            ? message.substring(0, maxLength) + '...'
            : message
        : message;

    return (
        <span onClick={toggleTruncate} style={{ cursor: 'pointer'}}>
            {displayedMessage}
            {message.length > maxLength && (
                isTruncated ? <span> (see more)</span> : <span> (see less)</span>
            )}
        </span>
    );
};

export default TruncateMessage;
